import React, { useContext, useEffect, useRef, useState } from 'react'
import StatisticsContext from '../StatisticsContext'
import { createRally } from '../../../../services/StatsService'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import MenuButtonsComponent from './MenuButtonsComponent'
import { actionsUtils } from '../Utils/ActionsUtils'
import { qualitiesAttack, qualitiesReception, qualitiesServe } from '../Utils/QualitiesUtils'
import { toast } from 'react-toastify'
import ShowGameStats from '../ShowGameStats'
import {Divider} from 'primereact/divider'


function GameGridStatisticsComponent() {

    const {gridData, playersA, setPlayersA, playersB, setPlayersB, rotationA, rotationB, setRotationA, setRotationB, 
        rotateA, rotateB, setRotateA, setRotateB, activeTeam, setActiveTeam, 
        activePlayersAContext, setActivePlayersAContext, activePlayersBContext, setActivePlayersBContext, 
        currentRallyContext, setCurrentRallyContext, handleRotationA, handleRotationB, currentActionRefContext, setMatchContext, 
        enabledAutoRotationContext} = useContext(StatisticsContext)
    const {userAuth} = useContext(AuthContext)
    const [gridLayout, setGridLayout] = useState(false)
    const [refreshReportData, setRefreshReportData] = useState(true)
    const [playerIndexSelected, setPlayerIndexSelected] = useState()
    const [showReceptionButtons, setShowReceptionButtons] = useState(true)
    const [initialLocalData, setInitialLocalData] = useState({})

    useEffect(()=>{
        gridData && handleInitialData()
    },[gridData])

    useEffect(()=>{
        currentActionRefContext.current = actions[5]
    },[])

    const handleSelectedPlayer = (element) => {
        currentPlayerRef.current = element

        setPlayerIndexSelected(element.player.access_key)
    }

    useEffect(()=>{
        var _initialLocalData = {...initialLocalData}
        if (playersA){
            playersARef.current = playersA
            _initialLocalData.playersA = playersA
        }

        if (playersB){
            playersBRef.current = playersB
            _initialLocalData.playersB = playersB
        }

        if (activePlayersAContext){
            _initialLocalData.activePlayersA = activePlayersAContext
        }

        if (activePlayersBContext){
            _initialLocalData.activePlayersB = activePlayersBContext
        }
        setInitialLocalData(_initialLocalData);
    },[playersA, playersB, activePlayersAContext, activePlayersBContext])

    const handleInitialData = async () => {
        const statsInitialData = await JSON.parse(localStorage.getItem("statsInitialData"));
        const initialDataIndex = statsInitialData?.length > 0 ? statsInitialData?.findIndex(e=>e.matchAccessKey === gridData?.matchAccessKey) : -1
        if (initialDataIndex >= 0) {
            setInitialLocalData(statsInitialData[initialDataIndex])
            statsInitialData[initialDataIndex].playersA && setPlayersA(statsInitialData[initialDataIndex].playersA)
            statsInitialData[initialDataIndex].playersB && setPlayersB(statsInitialData[initialDataIndex].playersB)
            statsInitialData[initialDataIndex].activePlayersA && setActivePlayersAContext(statsInitialData[initialDataIndex].activePlayersA)
            statsInitialData[initialDataIndex].activePlayersB && setActivePlayersBContext(statsInitialData[initialDataIndex].activePlayersB)
            statsInitialData[initialDataIndex].currentRallyContext && setCurrentRallyContext(statsInitialData[initialDataIndex].currentRallyContext)
            statsInitialData[initialDataIndex].rotationA && setRotationA(statsInitialData[initialDataIndex].rotationA)
            statsInitialData[initialDataIndex].rotationB && setRotationB(statsInitialData[initialDataIndex].rotationB)
        } else {
            setInitialLocalData({matchAccessKey: gridData?.matchAccessKey})
            setPlayersA(gridData?.playersTeamA?.map((e)=>{
                var color = '';
                if (e.position == 4) color = "#0dc61d"
                if (e.position == 1) color = "#3af25b"
                if (e.position == 3) color = "#8398c4"
                if (e.position == 6) color = "#323035"
                if (e.position == 2) color = "#87663b"
                if (e.position == 5) color = "#b0dee5"
                return (
                    {
                        ...e,
                        color,
                        courtPosition: Number(e.position),
                        label: Number(e.number),
                    }
                )
            }))
            setPlayersB(gridData?.playersTeamB?.map((e)=>{
                var color = '';
                if (e.position == 4) color = "#0dc61d"
                if (e.position == 1) color = "#3af25b"
                if (e.position == 3) color = "#8398c4"
                if (e.position == 6) color = "#323035"
                if (e.position == 2) color = "#87663b"
                if (e.position == 5) color = "#b0dee5"
                return (
                    {
                        ...e,
                        color,
                        courtPosition: Number(e.position),
                        label: Number(e.number),
                    }
                )
            }))
        }
    }

    useEffect(()=>{
        var _statsInitialDataList = JSON.parse(localStorage.getItem("statsInitialData"));
        const initialDataIndex = _statsInitialDataList?.length > 0 ? _statsInitialDataList?.findIndex(e=>e.matchAccessKey === initialLocalData?.matchAccessKey) : -1
        if (initialDataIndex === -1) 
        {
            _statsInitialDataList = _statsInitialDataList?.length > 0 ? [..._statsInitialDataList, initialLocalData] : [initialLocalData]
            localStorage.setItem("statsInitialData", JSON.stringify(_statsInitialDataList))
        } else {
            const index = _statsInitialDataList?.findIndex(e=>e.matchAccessKey === initialLocalData?.matchAccessKey)
            _statsInitialDataList[index] = initialLocalData
            localStorage.setItem("statsInitialData", JSON.stringify(_statsInitialDataList))
        }
        // localStorage.setItem("statsInitialData", JSON.stringify(null))
    },[initialLocalData])

    const switchActiveTeam = () => {
        activeTeamRef.current = activeTeamRef.current === 2 ? 1 : 2
        setActiveTeam(activeTeamRef.current)
    }

    const handleActiveTeam = (_activeTeam) => {
        activeTeamRef.current = _activeTeam
        setActiveTeam(_activeTeam)
    }

    useEffect(()=>{
        activeTeamRef.current = activeTeam
        teamServingRef.current = currentActionRefContext?.current?.number === "ser" ? activeTeam : teamServingRef.current
    },[activeTeam])

    useEffect(()=>{
        rotationARef.current = rotationA
        rotationBRef.current = rotationB
    },[rotationA, rotationB])

    const actions = [
        {
            ...actionsUtils.attack,
        },
        {
            ...actionsUtils.block,
        },
        {
            ...actionsUtils.set,
        },
        {
            ...actionsUtils.reception,
        },
        {
            ...actionsUtils.dig,
        },
        {
            ...actionsUtils.serve,
        },
    ]
    
    const handlePlayerBoxColor = (skill) => {
        if (skill === 10) return 'border-blue-400 bg-blue-100' 
        if (skill === 11) return 'border-bluegray-400 bg-bluegray-100'
        if (skill === 9) return 'border-yellow-400 bg-yellow-100'
        if (skill === 14) return 'border-purple-400 bg-purple-100'
        if (skill === 12) return 'border-pink-400 bg-pink-100'
    }
    
    const currentCourtPositionRef = useRef(null)
    const currentQualityRef = useRef();
    const currentPlayerRef = useRef()
    let currentPlayerAction = null;
    const currentRallyRef = useRef();
    const [selectedPosition, setSelectedPosition] = useState()
    const [clickedButton, setClickedButton] = useState()
    const activeTeamRef = useRef(activeTeam)
    const rotationARef = useRef()
    const rotationBRef = useRef()
    const [teamServing, setTeamServing] = useState()
    const teamServingRef = useRef(teamServing)
    const playersARef = useRef(playersA)
    const playersBRef = useRef(playersB)
    
    useEffect(()=>{
        playersARef.current = playersA
    },[playersA])

    useEffect(()=>{
        playersBRef.current = playersB
    },[playersB])

    useEffect(()=>{
        currentRallyRef.current = currentRallyContext
    },[currentRallyContext])

    const setCurrentRally = (_rally) => {
        currentRallyRef.current = _rally
        setCurrentRallyContext(_rally)
    }

    const getRallyPhase = () => {
        if (teamServingRef?.current !== activeTeamRef.current){
            if (currentRallyRef.current.length <= 3)
                return 'fbso'
            else
                return 'so'
        } else {
            return 'tra'
        }
    }

    const finishAction = () => {
        currentPlayerAction = {
            player: currentPlayerRef.current ? currentPlayerRef.current : null, 
            player_id: currentPlayerRef.current ? currentPlayerRef.current.player.id : null, 
            playerName: '', 
            action_number: currentActionRefContext.current?.number, 
            action: currentActionRefContext.current?.id, 
            position_action: currentCourtPositionRef.current ? currentCourtPositionRef.current : null, 
            quality_value: currentQualityRef.current ? currentQualityRef.current.number : null, 
            quality: currentQualityRef.current ? currentQualityRef.current.id : null, 
            rotation: activeTeamRef.current === 1 ? rotationARef.current : rotationBRef.current, 
            position: currentPlayerRef.current ? currentPlayerRef.current.courtPosition : "",
            team: activeTeamRef.current === 1 ? gridData.teamA : gridData.teamB, 
            activeTeam: activeTeamRef.current,
            rally_phase: getRallyPhase(),
            set_match: setMatchContext,
        }
        currentCourtPositionRef.current = null
        setCurrentRally([...currentRallyRef.current, currentPlayerAction])
        currentPlayerRef.current = null
    }

    const setCurrentPlayerAByPosition = (_position) => {
        if (playersARef.current && _position && typeof(_position) === 'number') {
            const _index = playersARef.current.findIndex(e=>Number(e.position) === Number(_position))
            currentPlayerRef.current = _index >= 0 ? {...playersARef.current[_index]} : null
        }
    }

    const setCurrentPlayerBByPosition = (_position) => {
        if (playersBRef.current && _position && typeof(_position) === 'number') {
            const _index = playersBRef.current.findIndex(e=>Number(e.position) === Number(_position))
            currentPlayerRef.current = _index >= 0 ? {...playersBRef.current[_index]} : null
        }
    }

    const handleTeamServing = (team) => {
        teamServingRef.current = team
        setTeamServing(team)
    }

    const finishGameRally = () => {
        var hasPoint = false
        var actionPoint = null
        for (let index = 0; index < currentRallyRef.current.length; index++) {
            const item = currentRallyRef.current[index];
            hasPoint = item.action_number === "ser" && (Number(item.quality_value) === 4 || Number(item.quality_value) === 0) ? true : false

            if ((Number(item.quality_value) === 2 && (
                item.action_number === "att" || 
                item.action_number === "blo"))) {
                hasPoint = true
                actionPoint = item
            } else if (Number(item.quality_value) === 0 && (
                item.action_number === "att" || 
                item.action_number === "set" || 
                item.action_number === "ser" || 
                item.action_number === "blo" || 
                item.action_number === "dig")) {
                hasPoint = true
                actionPoint = item
            }
            if (hasPoint) break;
        }

        hasPoint ? 
        createRally({match_id: gridData.matchId, created_by: userAuth.user.access_key, rally: currentRallyRef.current}).then((res)=>{
            if (res) {
                if (Number(actionPoint?.quality_value) === 2 && enabledAutoRotationContext) {
                    if (actionPoint.activeTeam !== teamServingRef.current) {
                        if (actionPoint.activeTeam === 1) {
                            handleRotationA(rotationARef.current)
                            handleActiveTeam(1)
                        } else {
                            handleRotationB(rotationBRef.current)
                            handleActiveTeam(2)
                        }
                    }
                } else if (Number(actionPoint?.quality_value) === 0 && enabledAutoRotationContext) {
                    if (actionPoint.activeTeam === teamServingRef.current) {
                        if (actionPoint.activeTeam === 1) {
                            handleRotationB(rotationBRef.current)
                            handleActiveTeam(2)
                        } else {
                            handleRotationA(rotationARef.current)
                            handleActiveTeam(1)
                        }
                    } else {
                        if (actionPoint.activeTeam === 2) {
                            handleActiveTeam(1)
                        } else {
                            handleActiveTeam(2)
                        }
                    }
                }
                setRefreshReportData(true)
                restartRally()
                toast.success("Rally saved!")
            }
            setShowReceptionButtons(true)
        }).catch((error)=>{
            setShowReceptionButtons(true)
        }) : toast.error("Click Score or Error")
    }

    const handleChangePosition = (e) => {
        setSelectedPosition(e.value)
        changePositionA(e.value)
    }

    const changePositionA = (position) => {
        var playersList = [...playersA]
        playersList?.forEach((item) => {
            item.position = item.number === item.number ? position : item.position
        });
        setPlayersA(playersList)
    }

    const changePlayerPosition = (_pos) => {
        if (_pos){
            var newPosition = _pos - 1
            newPosition = newPosition === 0 ? 6 : newPosition
            return newPosition
        }
    }

    useEffect(()=>{
        if (!rotateA) return
        rotationARef.current = rotationA
        var _initialLocalData = {...initialLocalData}
        _initialLocalData.rotationA = rotationA
        setInitialLocalData(_initialLocalData)
        if (playersA) {
            var tempPlayers = [...playersA]
            setPlayersA(tempPlayers.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
        if (activePlayersAContext) {
            var tempPlayersActiveContext = [...activePlayersAContext]
            setActivePlayersAContext(tempPlayersActiveContext.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
        setRotateA(false)
    },[rotateA])

    useEffect(()=>{
        if (!rotateB) return
        rotationBRef.current = rotationB
        var _initialLocalData = {...initialLocalData}
        _initialLocalData.rotationB = rotationB
        setInitialLocalData(_initialLocalData)
        if (playersB){
            var tempPlayers = [...playersB]
            setPlayersB(tempPlayers.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
        if (activePlayersBContext) {
            var tempPlayersActiveContext = [...activePlayersBContext]
            setActivePlayersBContext(tempPlayersActiveContext.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
        setRotateB(false)
    },[rotateB])


    const restartRally = () => {     
        currentActionRefContext.current = actions[5]
        setCurrentRally([])
    }

    const gridCss = 'flex flex-1 mt-4 text-2xl'
    const columnCss = 'flex flex-column flex-1 gap-2'
    const textGroupCss = 'flex gap-2'
    const textCss = 'border-1 border-round w-full h-6rem flex align-items-center justify-content-center'
    const pointCss = 'border-green-400 bg-green-300'
    const errorCss = 'border-red-400 bg-red-300'
    const noTextCss = 'w-full h-4rem'
    const serveCss = 'border-pink-400 bg-pink-100'
    const setCss = 'border-yellow-400 bg-yellow-100'
    const attackCss = 'border-cyan-400 bg-cyan-100'
    const clickedCss = 'border-bluegray-400 bg-bluegray-200'

    const handleActionPosition = (action, position, _activeTeam) => {
        if (!currentPlayerRef.current && action.number !== "set"){
            toast.info('Select a player')
            return;
        }

        if (action.number === "rec") {
            if (!currentPlayerRef.current){
                toast.info('Select a player')
                return;
            }
    
            const _currPlayerRef = currentPlayerRef.current
            if (_activeTeam === 1){
                setCurrentPlayerBByPosition(1)
                handleTeamServing(2)
            } else {
                setCurrentPlayerAByPosition(1)
                handleTeamServing(1)
            }
            //add server before adding reception
            setActiveTeam(_activeTeam === 1 ? 2 : 1)
            activeTeamRef.current = _activeTeam === 1 ? 2 : 1
            currentActionRefContext.current = actions[5]
            currentCourtPositionRef.current = position
            currentQualityRef.current = qualitiesServe.setPin
            finishAction()

            currentPlayerRef.current = _currPlayerRef
            setActiveTeam(_activeTeam)
            activeTeamRef.current = _activeTeam
            currentActionRefContext.current = action
            currentCourtPositionRef.current = position
            currentQualityRef.current = qualitiesAttack.one
            finishAction()
        } else if (action.number === "set"){
            let setter = []
            if (_activeTeam === 1){
                setter = activePlayersAContext.filter(e=>e.player.skill === 12)
            } else {
                setter = activePlayersBContext.filter(e=>e.player.skill === 12)
            }
            if (setter)
                currentPlayerRef.current = setter[0]
            else{
                toast.info('Select a player')
                return;                
            }
                
            setActiveTeam(_activeTeam)
            activeTeamRef.current = _activeTeam
            currentActionRefContext.current = action
            currentCourtPositionRef.current = position
            currentQualityRef.current = qualitiesAttack.one
            finishAction()
        } else {
            console.log('entrou 2')
            setActiveTeam(_activeTeam)
            activeTeamRef.current = _activeTeam
            currentActionRefContext.current = action
            currentCourtPositionRef.current = position
            currentQualityRef.current = qualitiesAttack.one
            finishAction()
        }
    }

    const handleReceptionQuality = (action, quality, _activeTeam) => {

        var _rallyContext = [...currentRallyContext]

        var qualityServe = null
        if (action.number === "rec"){
            if (quality.number === 4) { // rec A
                qualityServe = qualitiesServe.setMiddle
            } else if (quality.number === 3){ // rec B
                qualityServe = qualitiesServe.setMiddle
            } else if (quality.number === 2){ // rec C
                qualityServe = qualitiesServe.setPin
            } else if (quality.number === 1){ // rec D
                qualityServe = qualitiesServe.freeBall
            } else if (quality.number === 0){ // rec E
                qualityServe = qualitiesServe.point
                switchActiveTeam()
            }
    
            _rallyContext[0].quality = qualityServe ? qualityServe.id : qualitiesServe.error.id
            _rallyContext[0].quality_value = qualityServe ? qualityServe.number : qualitiesServe.error.number
            _rallyContext[1].quality = quality.id
            _rallyContext[1].quality_value = quality.number
        }
        setCurrentRallyContext(_rallyContext)
        setShowReceptionButtons(false)
    }

    const handleServeError = () => {
        activeTeam === 1 ? setCurrentPlayerAByPosition(1) : setCurrentPlayerBByPosition(1)
        currentCourtPositionRef.current = null
        currentActionRefContext.current = actions[5] // serve
        currentQualityRef.current = qualitiesServe.error
        finishAction()
    }

    const handleLastAction = (quality) => {
        const _rallyContext = [...currentRallyContext]
        
        const idx = _rallyContext.length-1
        
        _rallyContext[idx].quality_value = quality.number
        _rallyContext[idx].quality = quality.id

        setCurrentRallyContext(_rallyContext)
    }

    const receptionsQualitiesLayout = (_activeTeam) => {
        return (
            <div className={columnCss}>
                <div className={textGroupCss}>
                    <div role='button' onClick={()=>{handleReceptionQuality(actions[3], qualitiesReception.four, _activeTeam); setClickedButton(7+''+_activeTeam)}} className={`${textCss} border-green-400 bg-green-200 ${clickedButton === 7+''+_activeTeam ? clickedCss : ''}`}>Rec A</div>
                    <div role='button' onClick={()=>{handleReceptionQuality(actions[3], qualitiesReception.three, _activeTeam); setClickedButton(8+''+_activeTeam)}} className={`${textCss} border-green-400 bg-green-100 ${clickedButton === 8+''+_activeTeam ? clickedCss : ''}`}>Rec B</div>
                    <div role='button' onClick={()=>{handleReceptionQuality(actions[3], qualitiesReception.two, _activeTeam); setClickedButton(9+''+_activeTeam)}} className={`${textCss} border-yellow-400 bg-yellow-100 ${clickedButton === 9+''+_activeTeam ? clickedCss : ''}`}>Rec C</div>
                </div>
                <div className={textGroupCss}>
                    <div role='button' onClick={()=>{handleReceptionQuality(actions[3], qualitiesReception.one, _activeTeam); setClickedButton(10+''+_activeTeam)}} className={`${textCss} border-red-400 bg-red-100 ${clickedButton === 10+''+_activeTeam ? clickedCss : ''}`}>Rec D</div>
                    <div role='button' onClick={()=>{handleReceptionQuality(actions[3], qualitiesReception.zero, _activeTeam); setClickedButton(11+''+_activeTeam)}} className={`${textCss} border-red-400 bg-red-200 ${clickedButton === 11+''+_activeTeam ? clickedCss : ''}`}>Rec E</div>
                    <div role='button' className={`${noTextCss}`}></div>
                </div>
            </div>
        )
    }

    const receptionPositionsLayout = (_activeTeam) => {
        return(
            <div className={columnCss}>
                <div className={textGroupCss}>
                    <div onClick={()=>handleActionPosition(actions[3], 4, _activeTeam)} className={`${textCss} ${serveCss}`}>Rec 4</div>
                    <div onClick={()=>handleActionPosition(actions[3], 3, _activeTeam)} className={`${textCss} ${serveCss}`}>Rec 3</div>
                    <div onClick={()=>handleActionPosition(actions[3], 2, _activeTeam)} className={`${textCss} ${serveCss}`}>Rec 2</div>
                </div>
                <div className={textGroupCss}>
                    <div onClick={()=>handleActionPosition(actions[3], 5, _activeTeam)} className={`${textCss} ${serveCss}`}>Rec 5</div>
                    <div onClick={()=>handleActionPosition(actions[3], 6, _activeTeam)} className={`${textCss} ${serveCss}`}>Rec 6</div>
                    <div onClick={()=>handleActionPosition(actions[3], 1, _activeTeam)} className={`${textCss} ${serveCss}`}>Rec 1</div>
                </div>
            </div>
        )
    }

    const setPositionsLayout = (_activeTeam) => {
        return(
            <div className={columnCss}>
                <div className={textGroupCss}>
                    <div onClick={()=>{handleActionPosition(actions[2], 4, _activeTeam); setClickedButton(12+''+_activeTeam)}} className={`${textCss} ${setCss} ${clickedButton === 12+''+_activeTeam ? clickedCss : ''}`}>Set 4</div>
                    <div onClick={()=>{handleActionPosition(actions[2], 3, _activeTeam); setClickedButton(13+''+_activeTeam)}} className={`${textCss} ${setCss} ${clickedButton === 13+''+_activeTeam ? clickedCss : ''}`}>Set 3</div>
                    <div onClick={()=>{handleActionPosition(actions[2], 2, _activeTeam); setClickedButton(14+''+_activeTeam)}} className={`${textCss} ${setCss} ${clickedButton === 14+''+_activeTeam ? clickedCss : ''}`}>Set 2</div>
                </div>
                <div className={textGroupCss}>
                    <div onClick={()=>{handleActionPosition(actions[2], 5, _activeTeam); setClickedButton(15+''+_activeTeam)}} className={`${textCss} ${setCss} ${clickedButton === 15+''+_activeTeam ? clickedCss : ''}`}>Set 5</div>
                    <div onClick={()=>{handleActionPosition(actions[2], 6, _activeTeam); setClickedButton(16+''+_activeTeam)}} className={`${textCss} ${setCss} ${clickedButton === 16+''+_activeTeam ? clickedCss : ''}`}>Set 6</div>
                    <div onClick={()=>{handleActionPosition(actions[2], 1, _activeTeam); setClickedButton(17+''+_activeTeam)}} className={`${textCss} ${setCss} ${clickedButton === 17+''+_activeTeam ? clickedCss : ''}`}>Set 1</div>
                </div>
            </div>
        )
    }

    const playersAPositionsLayout = (_activeTeam) => {
        return(
            <div className={columnCss}>
                <div className={textGroupCss}>
                    {
                    activePlayersAContext?.sort((a, b) => a.player.order - b.player.order).slice(0,3).map((element, key)=>(
                        <div key={key} onClick={()=>handleSelectedPlayer(element)}
                        className={`flex flex-column gap-2 p-2 border-2 border-round flex-1
                        ${playerIndexSelected === element.player.access_key ? 'border-green-400 bg-green-200' : handlePlayerBoxColor(element.player.skill)}`}>
                            <div className='text-xl flex gap-1 align-items-center font-bold'>
                                {element.number < 10 ? `0${element.number}` : element.number}
                            </div>
                            <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis max-w-6rem'>
                                {element.player.first_name} {element.player.last_name}
                            </div>
                        </div> 
                        ))
                    }
                </div> 
                <div className={textGroupCss}>
                    {
                    activePlayersAContext?.sort((a, b) => a.player.order - b.player.order).slice(3,6).map((element, key)=>(
                        <div key={key} onClick={()=>handleSelectedPlayer(element)}
                        className={`flex flex-column gap-2 p-2 border-2 border-round flex-1
                        ${playerIndexSelected === element.player.access_key ? 'border-green-400 bg-green-200' : handlePlayerBoxColor(element.player.skill)}`}>
                            <div className='text-xl flex gap-1 align-items-center font-bold'>
                                {element.number < 10 ? `0${element.number}` : element.number}
                            </div>
                            <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis max-w-6rem'>
                                {element.player.first_name} {element.player.last_name}
                            </div>
                        </div> 
                        ))
                    }
                </div> 
            </div>
        )
    }
    
    const playersBPositionsLayout = (_activeTeam) => {
        return(
            <div className={columnCss}>
                <div className={textGroupCss}>
                    {
                    activePlayersBContext?.sort((a, b) => a.player.order - b.player.order).slice(0,3).map((element, key)=>(
                        <div key={key} onClick={()=>handleSelectedPlayer(element)}
                        className={`flex flex-column gap-2 p-2 border-2 border-round flex-1
                        ${playerIndexSelected === element.player.access_key ? 'border-green-400 bg-green-200' : handlePlayerBoxColor(element.player.skill)}`}>
                            <div className='text-xl flex gap-1 align-items-center font-bold'>
                                {element.number < 10 ? `0${element.number}` : element.number}
                            </div>
                            <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis max-w-6rem'>
                                {element.player.first_name} {element.player.last_name}
                            </div>
                        </div> 
                        ))
                    }
                </div> 
                <div className={textGroupCss}>
                    {
                    activePlayersBContext?.sort((a, b) => a.player.order - b.player.order).slice(3,6).map((element, key)=>(
                        <div key={key} onClick={()=>handleSelectedPlayer(element)}
                        className={`flex flex-column gap-2 p-2 border-2 border-round flex-1
                        ${playerIndexSelected === element.player.access_key ? 'border-green-400 bg-green-200' : handlePlayerBoxColor(element.player.skill)}`}>
                            <div className='text-xl flex gap-1 align-items-center font-bold'>
                                {element.number < 10 ? `0${element.number}` : element.number}
                            </div>
                            <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis max-w-6rem'>
                                {element.player.first_name} {element.player.last_name}
                            </div>
                        </div> 
                        ))
                    }
                </div> 
            </div>
        )
    }
    
    const attackPositionsLayout = (_activeTeam) => {
        return(
            <div className={columnCss}>
                <div className={textGroupCss}>
                    <div role='button' onClick={()=>{handleActionPosition(actions[0], 4, _activeTeam); setClickedButton(1+''+_activeTeam)}} className={`${textCss} ${attackCss} ${clickedButton === 1+''+_activeTeam ? clickedCss : ''}`}>Att 4</div>
                    <div role='button' onClick={()=>{handleActionPosition(actions[0], 3, _activeTeam); setClickedButton(2+''+_activeTeam)}} className={`${textCss} ${attackCss} ${clickedButton === 2+''+_activeTeam ? clickedCss : ''}`}>Att 3</div>
                    <div role='button' onClick={()=>{handleActionPosition(actions[0], 2, _activeTeam); setClickedButton(3+''+_activeTeam)}} className={`${textCss} ${attackCss} ${clickedButton === 3+''+_activeTeam ? clickedCss : ''}`}>Att 2</div>
                </div>
                <div className={textGroupCss}>
                    <div role='button' onClick={()=>{handleActionPosition(actions[0], 5, _activeTeam); setClickedButton(4+''+_activeTeam)}} className={`${textCss} ${attackCss} ${clickedButton === 4+''+_activeTeam ? clickedCss : ''}`}>Att 5</div>
                    <div role='button' onClick={()=>{handleActionPosition(actions[0], 6, _activeTeam); setClickedButton(5+''+_activeTeam)}} className={`${textCss} ${attackCss} ${clickedButton === 5+''+_activeTeam ? clickedCss : ''}`}>Att 6</div>
                    <div role='button' onClick={()=>{handleActionPosition(actions[0], 1, _activeTeam); setClickedButton(6+''+_activeTeam)}} className={`${textCss} ${attackCss} ${clickedButton === 6+''+_activeTeam ? clickedCss : ''}`}>Att 1</div>
                </div>
            </div>
        )
    }

    const miscLayout = (_activeTeam) => {
        return (
            <div className={columnCss}>
                <div className={textGroupCss}>
                    <div role='button' onClick={()=>{handleActionPosition(actions[1], null, _activeTeam); setClickedButton(18+''+_activeTeam)}} className={`${textCss} border-purple-400 bg-purple-100 ${clickedButton === 18+''+_activeTeam ? clickedCss : ''}`}>Blo</div>
                    <div role='button' onClick={()=>{handleActionPosition(actions[4], null, _activeTeam); setClickedButton(19+''+_activeTeam)}} className={`${textCss} border-indigo-400 bg-indigo-100 ${clickedButton === 19+''+_activeTeam ? clickedCss : ''}`}>Dig</div>
                    <div role='button' onClick={()=>{handleServeError(); setClickedButton(22+''+_activeTeam)}} className={`${textCss} border-orange-400 bg-orange-200 ${clickedButton === 22+''+_activeTeam ? clickedCss : ''}`}>Ser E</div>
                </div>
                <div className={textGroupCss}>
                    <div onClick={()=>{handleLastAction(qualitiesAttack.two); setClickedButton(20+''+_activeTeam)}} className={`${textCss} ${pointCss} ${clickedButton === 20+''+_activeTeam ? clickedCss : ''}`}>Score</div>                        
                    <div onClick={()=>{handleLastAction(qualitiesAttack.zero); setClickedButton(21+''+_activeTeam)}} className={`${textCss} ${errorCss} ${clickedButton === 21+''+_activeTeam ? clickedCss : ''}`}>Error</div>                        
                </div>
            </div>
        )
    }

    return (
        <div>
            <div>
                <MenuButtonsComponent 
                    gridLayout={gridLayout} 
                    setGridLayout={setGridLayout} 
                    selectedPosition={selectedPosition} 
                    handleChangePosition={handleChangePosition} 
                    setTeamServing={setTeamServing}
                    courtType={''}
                    saveRally={finishGameRally}
                    restartRally={restartRally}
                />
                <div className={gridCss}>
                    <div className={columnCss}>
                        <div>
                            {playersAPositionsLayout(1)}
                        </div>
                        <div>
                            {showReceptionButtons && ((activeTeam === 1 && currentActionRefContext?.current?.number === "rec") ?  receptionsQualitiesLayout(1) :
                                receptionPositionsLayout(1)) }
                        </div> 
                        <div>
                            {setPositionsLayout(1)}
                        </div>
                        <div>
                            {attackPositionsLayout(1)}
                        </div>
                        <div>
                            {miscLayout(1)}
                        </div>
                    </div>
                    <Divider layout="vertical" />
                    <div className={columnCss}>
                        <div>
                            {playersBPositionsLayout(2)}
                        </div>
                        <div>
                            {showReceptionButtons && ((activeTeam === 2 && currentActionRefContext?.current?.number === "rec") ?  receptionsQualitiesLayout(2) :
                                receptionPositionsLayout(2)) }
                        </div>
                        <div>
                            {setPositionsLayout(2)}
                        </div>
                        <div>
                            {attackPositionsLayout(2)}
                        </div>
                        <div>
                            {miscLayout(2)}
                        </div>
                    </div>                  
                </div>
            </div>
            <Divider />
            <ShowGameStats refresData={refreshReportData} setRefreshReportData={setRefreshReportData} />
        </div>
    )

}

export default GameGridStatisticsComponent