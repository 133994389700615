import React, { useState, useRef, useContext } from 'react'
import './AppHead.css'
import leagr_logo from '../../images/leagr_logo_129x50.png'
import {PiChartBarBold, PiGearBold, PiHouseBold, PiListBold, PiSignOutBold, PiTrophyBold, PiUserBold, PiUsersThreeBold, PiXBold} from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../context/AuthContext/AuthContext'
import { Avatar } from 'primereact/avatar'
import { Button } from 'primereact/button'
import { toast } from 'react-toastify'
import { Copy, ShareFat } from '@phosphor-icons/react'

function AppHead({activeMenuNumber=1}) {

  const navigate = useNavigate()
  const [hideMenu, setHideMenu] = useState(true);
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const [userImage, setUserImage] = useState('')
  const [activeMenu, setActiveMenu] = useState(activeMenuNumber);
  const {logOut, userAuth} = useContext(AuthContext)

  const handleHideMenu = () => {
    setHideMenu(!hideMenu)
  }

  const handleLogout = () => {
    logOut()
    navigate('/')
  }

  const handleMenu = (number) => {
    setActiveMenu(number);
    number === 1 && navigate('/app/home');
    number === 2 && navigate('/app/practice_home');
    number === 3 && navigate('/app/player_list');
    // number === 4 && navigate('/app/chat_list');
    number === 5 && navigate('/app/profile_form');
    number === 6 && navigate('/app/settings');
    number === 8 && navigate('/app/mystats');
    if (number === 7)  {
      userAuth && logOut(userAuth.user.access_key);
      navigate('/');
    }
    setHideMenu(true)
  }

  const handleCopyUserUrl = () => {
    navigator.clipboard.writeText('leagr.net/'+userAuth?.user?.username)
    toast.success(`Your ${'leagr.net/'+userAuth?.user?.username} link is copied.`)
  }

  return (
    <>
    {windowSize.current[0] > 780 ?
      
      <div className='app_head' >
        <div className="app_head--head">
          <img src={leagr_logo} alt='Leagr logo' className='logo--size'/>
        </div>
        <div className="app_head--actions">
          <Avatar icon="pi pi-user" image={userAuth?.user?.picture_url} size='large' shape='circle' />
          <Button text onClick={handleCopyUserUrl}>share <ShareFat size={18} /></Button>
          {userAuth?.user && <Button className='text-white' text onClick={handleLogout} size='small'><PiSignOutBold size={18} /></Button>}
        </div>
      </div>
      
      :
      
      <div className='app_head' >
        <div className="app_head--head">
          <a href='/'><img src={leagr_logo} alt='Leagr logo' className='logo--size'/></a>
          <div className="app_head--right">
            <Avatar icon="pi pi-user" image={userAuth?.user?.picture_url} shape='circle' />
            <Button text onClick={handleCopyUserUrl} size='small'>share <ShareFat size={12} /></Button>
            {hideMenu ? 
            <PiListBold onClick={handleHideMenu} color='#FFF' className='icons' />
            : <PiXBold onClick={handleHideMenu} color='#FFF' className='icons' />}
          </div>
        </div>
        { !hideMenu && 
        <div className="app_head--actions">
          {userAuth?.user ?             
          <div className="app_head--menu">
            <a role='button' onClick={() => handleMenu(1)} className={'text--regular--normal '+(activeMenu===1 ? 'app_head--menu--item--selected' : 'app_head--menu--item')}>
              <span className={activeMenu===1 ? 'app_head--menu--icon--selected' : "app_navbar--menu--icon"}><PiHouseBold /></span> Home
            </a>
            
            <a role='button' onClick={() => handleMenu(2)} className={'text--regular--normal '+(activeMenu===2 ? 'app_head--menu--item--selected' : 'app_head--menu--item')}>
              <span className={activeMenu===2 ? 'app_head--menu--icon--selected' : "app_navbar--menu--icon"}><PiTrophyBold /></span> Practice
            </a>

            <a role='button' onClick={() => handleMenu(3)} className={'text--regular--normal '+(activeMenu===3 ? 'app_head--menu--item--selected' : 'app_head--menu--item')}>
              <span className={activeMenu===3 ? 'app_head--menu--icon--selected' : "app_navbar--menu--icon"}><PiUsersThreeBold /></span> Feedback
            </a>

            {/* <a role='button' onClick={() => handleMenu(4)} className={'text--regular--normal '+(activeMenu===4 ? 'app_head--menu--item--selected' : 'app_head--menu--item')}>
              <span className={activeMenu===4 ? 'app_head--menu--icon--selected' : "app_navbar--menu--icon"}><PiChatsBold /></span> Chat
            </a> */}


            <a role='button' onClick={() => handleMenu(8)} className={'text--regular--normal '+(activeMenu===8 ? 'app_head--menu--item--selected' : 'app_head--menu--item')}>
              <span className={activeMenu===8 ? 'app_head--menu--icon--selected' : "app_navbar--menu--icon"}><PiChartBarBold /></span> Stats
            </a>

            <a role='button' onClick={() => handleMenu(5)} className={'text--regular--normal '+(activeMenu===5 ? 'app_head--menu--item--selected' : 'app_head--menu--item')}>
              <span className={activeMenu===5 ? 'app_head--menu--icon--selected' : "app_navbar--menu--icon"}><PiUserBold /></span> Profile
            </a>

            {/* <a role='button' onClick={() => handleMenu(6)} className={'text--regular--normal '+(activeMenu===6 ? 'app_head--menu--item--selected' : 'app_head--menu--item')}>
              <span className={activeMenu===6 ? 'app_head--menu--icon--selected' : "app_navbar--menu--icon"}><PiGearBold /></span> Settings
            </a> */}

            <a role='button' onClick={() => handleMenu(7)} className={'text--regular--normal '+(activeMenu===7 ? 'app_head--menu--item--selected' : 'app_head--menu--item')}>
              <span className={activeMenu===7 ? 'app_head--menu--icon--selected' : "app_navbar--menu--icon"}><PiSignOutBold onClick={handleLogout} /></span> Log Out
            </a>
          </div>
          : 
          <div className="app_head--menu">
            <Button onClick={()=>navigate("/register")} label='Register' className='w-full' />
            <Button onClick={()=>navigate("/login")} label='Login' className='w-full' />
          </div>
          }
        </div>
        }
      </div>
    }
    </>
  )
}

export default AppHead