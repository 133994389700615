import React from 'react'
import { PiArrowClockwise } from 'react-icons/pi'
import { handleAttBackgroundColor, handleRecBackgroundColor, handleSerBackgroundColor } from './Utils/ActionsUtils'

function ShowGameStatsItemComponent({item, teamAName, setAttackEfficiencyList}) {
    const getColor = (total, quality) => {
        if (item.action_id === 1) { //serve 
            return handleSerBackgroundColor(total, quality)
        }
        else if (item.action_id === 2) { // reception
            return handleRecBackgroundColor(total, quality)
        }
        if (item.action_id === 3 || item.action_id === 4) { //set or attack
            return handleAttBackgroundColor(total, quality)
        }
    }

    const handleCourtColor = () => {
        return item.team_name === teamAName ? '#86bbd8' : '#f6ae2d'
    }

    const handleQualities = (pos) => {
        if (item.action_id === 1 || item.action_id === 2) { //serve or reception
            const total = Number(item['quality'+pos+'0']) + 
            Number(item['quality'+pos+'1']) + 
            Number(item['quality'+pos+'2']) + 
            Number(item['quality'+pos+'3']) + 
            Number(item['quality'+pos+'4'])
            return parseFloat(total ? (
                Number(item['quality'+pos+'1']) + 
                Number(item['quality'+pos+'2']) * 2 + 
                Number(item['quality'+pos+'3']) * 3 + 
                Number(item['quality'+pos+'4']) * 4) / (
                Number(item['quality'+pos+'0']) + 
                Number(item['quality'+pos+'1']) + 
                Number(item['quality'+pos+'2']) + 
                Number(item['quality'+pos+'3']) + 
                Number(item['quality'+pos+'4']) 
                ) : 0).toFixed(2)
        }
        else if (item.action_id === 4) { //att
            const total = Number(item['quality'+pos+'0']) + 
            Number(item['quality'+pos+'1']) + 
            Number(item['quality'+pos+'2'])
            return parseFloat(total > 0 ? (
                Number(item['quality'+pos+'2']) - Number(item['quality'+pos+'0'])) / (
                Number(item['quality'+pos+'0']) + 
                Number(item['quality'+pos+'1']) + 
                Number(item['quality'+pos+'2']))
            : 0).toFixed(2)
        }
        else if (item.action_id === 3 && setAttackEfficiencyList) { //set
            const idx = setAttackEfficiencyList?.findIndex(e=>e.set_position === pos && e.team_id === item.team_id && e.rotation_number === Number(item.rotation_number))
            return parseFloat(idx > -1 ? Number(setAttackEfficiencyList[idx].efficiency) : 0).toFixed(3)
        }
    }

    return (
        <div className={`flex flex-column flex-1 p-2 text-black border-round max-w-10rem`} style={{backgroundColor: handleCourtColor()}}>
            <div className='mb-2'>
                <div className='flex align-items-center justify-content-between text-xl font-bold'>
                    <div>{item.action_name}</div>
                    <div className='flex align-items-center'><PiArrowClockwise className='-rotate-90' size={20} /> {item.rotation_id}</div>
                </div>
                <div className='text-sm'>
                    {item.team_name}   
                </div>
            </div>
            <div className='flex h-4rem text-sm bg-white'>
                <div className={`border-right-none col-4 border-1 flex flex-column justify-content-center align-items-center ${getColor(item.courtPositionFour, handleQualities(4))}`}>
                    <div>{item.courtPositionFour}</div>
                    <div>{parseFloat(Number(item.courtPositionFour) / item.courtPositionTotal * 100).toFixed(0)+"%"}</div>
                    <div>{handleQualities(4)}</div>
                </div>
                <div className={`border-x-none col-4 border-1 flex flex-column justify-content-center align-items-center ${getColor(item.courtPositionThree, handleQualities(3))}`}>
                    <div>{item.courtPositionThree}</div>
                    <div>{parseFloat(Number(item.courtPositionThree) / item.courtPositionTotal * 100).toFixed(0)+"%"}</div>
                    <div>{handleQualities(3)}</div>
                </div>
                <div className={`border-left-none col-4 border-1 flex flex-column justify-content-center align-items-center ${getColor(item.courtPositionTwo, handleQualities(2))}`}>
                    <div>{item.courtPositionTwo}</div>
                    <div>{parseFloat(Number(item.courtPositionTwo) / item.courtPositionTotal * 100).toFixed(0)+"%"}</div>
                    <div>{handleQualities(2)}</div>
                </div>
            </div>
            <div className='flex h-6rem text-sm bg-white'>
                <div className={`border-right-none col-4 border-1 flex flex-column justify-content-center align-items-center ${getColor(item.courtPositionFive, handleQualities(5))}`}>
                    <div>{item.courtPositionFive}</div>
                    <div>{parseFloat(Number(item.courtPositionFive) / item.courtPositionTotal * 100).toFixed(0)+"%"}</div>
                    <div>{handleQualities(5)}</div>
                </div>
                <div className={`border-x-none col-4 border-1 flex flex-column justify-content-center align-items-center ${getColor(item.courtPositionSix, handleQualities(6))}`}>
                    <div>{item.courtPositionSix}</div>
                    <div>{parseFloat(Number(item.courtPositionSix) / item.courtPositionTotal * 100).toFixed(0)+"%"}</div>
                    <div>{handleQualities(6)}</div>
                </div>
                <div className={`border-left-none col-4 border-1 flex flex-column justify-content-center align-items-center ${getColor(item.courtPositionOne, handleQualities(1))}`}>
                    <div>{item.courtPositionOne}</div>
                    <div>{parseFloat(Number(item.courtPositionOne) / item.courtPositionTotal * 100).toFixed(0)+"%"}</div>
                    <div>{handleQualities(1)}</div>
                </div>
            </div>
        </div>
    )
}

export default ShowGameStatsItemComponent