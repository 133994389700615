import React, { useContext, useState } from 'react'
import AppBodyComponent from '../../../../components/AppBody/AppBodyComponent'
import './PracticeHome.css'
import { useNavigate } from 'react-router-dom'
import SportAutocompleteComponent from '../../../../components/SportAutocompleteComponent/SportAutocompleteComponent'
import { Button } from 'primereact/button'
import { Faders, Plus } from '@phosphor-icons/react'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import LeagueAutocompleteComponent from '../../../../components/LeagueAutocompleteComponent/LeagueAutocompleteComponent'
import ReactGA from 'react-ga4'        
import { Panel } from 'primereact/panel'

function PracticeHome() {

    ReactGA.send({ hitType: "pageview", page: "/event_list", title: "Event List" });
    const {userAuth} = useContext(AuthContext)


    const [modalIsOpen, setModalIsOpen] = useState(false);
    
    const [name, setName] = useState('');
    const [address, setAddress] = useState(userAuth.user.zipcode)
    const [addressMarker, setAddressMarker] = useState({lat: userAuth.user.lat, lng: userAuth.user.lng})
    const [miles, setMiles] = useState(userAuth?.user?.setting_miles_distance || 500)
    const [sport, setSport] = useState('')
    const [league, setLeague] = useState('')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(()=>{
        var _date = new Date(startDate.toDateString())
        _date.setDate(_date.getDate() + 90)
        return _date
    })
    const [showSearchAreaButton, setShowSearchAreaButton] = useState(false)

    const navigate = useNavigate();

    const handleModalIsOpen = () => {
        setModalIsOpen(!modalIsOpen)
    }

    const handleConfirmFilter = async () => {
        setModalIsOpen(!modalIsOpen)
    }

    const handleCancelFilter = () => {
        setModalIsOpen(!modalIsOpen)
    }

    const  handleAddPractice = () =>{
        navigate('/app/create_practice')
    }

    const  handleAddDrill = () =>{
        navigate('/app/create_drill')
    }

    return (
        <AppBodyComponent activeMenuNumber={2}>
            <div className="event_list--container">

                <Panel unstyled>
                    <div className="flex justify-content-between flex-wrap p-2 bg-blue-900 w-full border-round gap-2">
                        <div className="flex align-content-center flex-wrap gap-2">
                            <Button onClick={handleAddPractice} size='small'><Plus weight='bold' /> Create Practice</Button>
                            <Button onClick={handleAddDrill} size='small'><Plus weight='bold' /> Create Drill</Button>
                        </div>
                        <div className="flex align-content-center flex-wrap gap-2">
                            <Button severity='secondary' onClick={handleModalIsOpen} size='small'><Faders weight='bold' /></Button>
                        </div>
                    </div>
                </Panel>



                <Dialog header="Filter" visible={modalIsOpen} onHide={() => setModalIsOpen(false)} 
                footer={
                    <div>
                        <Button label="Cancel" icon="pi pi-times" onClick={handleCancelFilter} className="p-button-text" />
                        <Button label="Apply" icon="pi pi-check" onClick={handleConfirmFilter} autoFocus />
                    </div>
                }>
                    <div className="flex flex-column gap-2">

                        <div className="flex flex-column">
                            <div className="flex flex-row flex-wrap gap-2">
                                <div className='flex flex-column'>
                                    <label htmlFor='startDate' className='text--small--normal'>Start date</label>
                                    <Calendar value={startDate} onChange={(e)=>setStartDate(e.target.value)} className='p-inputtext-sm' name="startDate" id="startDate" />
                                </div>
                                <div className='flex flex-column'>
                                    <label htmlFor='endDate' className='text--small--normal'>End date</label>
                                    <Calendar value={endDate} onChange={(e)=>setEndDate(e.target.value)} className='p-inputtext-sm' name="endDate" id="endDate"/>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-column'>
                            <label htmlFor='search' className='text--small--normal'>Event name</label>
                            <InputText autoComplete='off' value={name} onChange={(e)=>setName(e.target.value)} className='p-inputtext-sm' name="search" id="search" placeholder='' />
                        </div>

                        <div className='flex flex-column'>
                            <label htmlFor='league' className='text--small--normal'>League</label>
                            <LeagueAutocompleteComponent league={league} setLeague={(value)=>setLeague(value)} size='p-inputtext-sm' />
                        </div>

                        <div className='flex flex-column'>
                            <label htmlFor='sport' className='text--small--normal'>Sport</label>
                            <SportAutocompleteComponent sport={sport} setSport={(value)=>setSport(value)} size='p-inputtext-sm' />
                        </div>

                        <div className='flex flex-column'>
                            <label htmlFor='search' className='text--small--normal'>Address</label>
                            <InputText value={address} onChange={(e)=>setAddress(e.target.value)} className='p-inputtext-sm' name="address" id="address" />
                            <small>Address, zipcode, city, ...</small>
                        </div>
                        <div className='flex flex-column'>
                            <label htmlFor='search' className='text--small--normal'>Distance</label>
                            <InputText value={miles} onChange={(e)=>setMiles(e.target.value)} className='p-inputtext-sm' type="number" name="miles" id="miles"/>
                            <small>Enter the distance in miles</small>
                        </div>

                    </div>
                </Dialog>





                <div className="event_list--content">
                    
                </div>
            </div>
        </AppBodyComponent>
    )
}

export default PracticeHome