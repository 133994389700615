import React, { useState, useRef, useContext } from 'react'
import {PiChartBar, PiChartBarBold, PiChatsBold, PiGearBold, PiHouseBold, PiSignOutBold, PiTrophyBold, PiUserBold, PiUsersThreeBold} from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import './AppNavbar.css';
import { signOut } from 'firebase/auth';
import AuthContext from '../../context/AuthContext/AuthContext';
import { Button } from 'primereact/button';

function AppNavbar({activeMenuNumber=1}) {

    const [activeMenu, setActiveMenu] = useState(activeMenuNumber);
    const navigate = useNavigate()
    const windowSize = useRef([window.innerWidth, window.innerHeight])
    const {logOut, userAuth} = useContext(AuthContext)

    const handleMenu = (number) => {
        setActiveMenu(number);
        number === 1 && navigate('/app/home');
        number === 2 && navigate('/app/practice_home');
        number === 3 && navigate('/app/player_list');
        // number === 4 && navigate('/app/chat_list');
        number === 5 && navigate('/app/profile_form');
        number === 6 && navigate('/app/settings');
        number === 8 && navigate('/app/mystats');
        if (number === 7)  {
            userAuth && logOut(userAuth.user.access_key);
            navigate('/');
        }
    }

    return (
        <div>
            {windowSize.current[0] > 780 &&
                <div className="app_navbar--container">
                    {
                        userAuth?.user ? 
                        <div className="app_navbar--menu">
                            <a role='button' onClick={() => handleMenu(1)} className={'text--medium--bold '+(activeMenu===1 ? 'app_navbar--menu--item--selected' : 'app_navbar--menu--item')}>
                                <span className={activeMenu===1 ? 'app_navbar--menu--icon--selected' : 'app_navbar--menu--icon'}>
                                    <PiHouseBold className='app_navbar--icons' />
                                </span> 
                                Home
                            </a>
                            
                            <a role='button' onClick={() => handleMenu(2)} className={'text--medium--bold '+(activeMenu===2 ? 'app_navbar--menu--item--selected' : 'app_navbar--menu--item')}>
                                <span className={activeMenu===2 ? 'app_navbar--menu--icon--selected' : 'app_navbar--menu--icon'}>
                                    <PiTrophyBold className='app_navbar--icons' />
                                </span> 
                                Practice
                            </a>

                            <a role='button' onClick={() => handleMenu(3)} className={'text--medium--bold '+(activeMenu===3 ? 'app_navbar--menu--item--selected' : 'app_navbar--menu--item')}>
                                <span className={activeMenu===3 ? 'app_navbar--menu--icon--selected' : 'app_navbar--menu--icon'}>
                                    <PiUsersThreeBold className='app_navbar--icons' />
                                </span> 
                                Feedback
                            </a>

                            {/* <a role='button' onClick={() => handleMenu(4)} className={'text--medium--bold '+(activeMenu===4 ? 'app_navbar--menu--item--selected' : 'app_navbar--menu--item')}>
                                <span className={activeMenu===4 ? 'app_navbar--menu--icon--selected' : 'app_navbar--menu--icon'}>
                                    <PiChatsBold className='app_navbar--icons' />
                                </span> 
                                Chat
                            </a> */}

                            <a role='button' onClick={() => handleMenu(8)} className={'text--medium--bold '+(activeMenu===8 ? 'app_navbar--menu--item--selected' : 'app_navbar--menu--item')}>
                                <span className={activeMenu===8 ? 'app_navbar--menu--icon--selected' : 'app_navbar--menu--icon'}>
                                    <PiChartBarBold className='app_navbar--icons' />
                                </span> 
                                Stats
                            </a>

                            <a role='button' onClick={() => handleMenu(5)} className={'text--medium--bold '+(activeMenu===5 ? 'app_navbar--menu--item--selected' : 'app_navbar--menu--item')}>
                                <span className={activeMenu===5 ? 'app_navbar--menu--icon--selected' : 'app_navbar--menu--icon'}>
                                    <PiUserBold className='app_navbar--icons' />
                                </span> 
                                Profile
                            </a>

                            {/* <a role='button' onClick={() => handleMenu(6)} className={'text--medium--bold '+(activeMenu===6 ? 'app_navbar--menu--item--selected' : 'app_navbar--menu--item')}>
                                <span className={activeMenu===6 ? 'app_navbar--menu--icon--selected' : 'app_navbar--menu--icon'}>
                                    <PiGearBold className='app_navbar--icons' />
                                </span> 
                                Settings
                            </a> */}

                            <a role='button' onClick={() => handleMenu(7)} className={'text--medium--bold '+(activeMenu===7 ? 'app_navbar--menu--item--selected' : 'app_navbar--menu--item')}>
                                <span className={activeMenu===7 ? 'app_navbar--menu--icon--selected' : 'app_navbar--menu--icon'}>
                                    <PiSignOutBold className='app_navbar--icons' />
                                </span> 
                                Log Out
                            </a>
                        </div> 
                        
                        :
                        
                        <div className="app_navbar--menu">
                            <Button onClick={()=>navigate("/register")} label='Register' className='w-full' />
                            <Button onClick={()=>navigate("/login")} label='Login' className='w-full' />
                        </div>
                    }
                </div> 
            }           
        </div>
    )
}

export default AppNavbar