import { TabPanel, TabView } from 'primereact/tabview'
import React from 'react'
import MyStatistics from './MyStatistics'
import MyTeams from './MyTeams'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'

function MyStatsHome() {
    return (
        <AppBodyComponent activeMenuNumber={8}>
            <TabView>
                <TabPanel header="My Statistics">
                    <MyStatistics />
                </TabPanel>
                <TabPanel header="My Teams">
                    <MyTeams />
                </TabPanel>
            </TabView>
        </AppBodyComponent>
    )
}

export default MyStatsHome