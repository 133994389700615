import React, { useContext, useEffect, useState } from 'react'
import StatisticsContext from '../StatisticsContext'

function MiniCourtRotationComponent({teamAorB}) {
    const {activePlayersAContext, setActivePlayersAContext, activePlayersBContext, setActivePlayersBContext} = useContext(StatisticsContext)
    
    const getPlayer = (position) => {

        let index = -1

        if (teamAorB === 1){
            index = activePlayersAContext?.findIndex(e=>Number(e.position) === position)
            return activePlayersAContext[index]
        } else if (teamAorB === 2){
            index = activePlayersBContext?.findIndex(e=>Number(e.position) === position)
            return activePlayersBContext[index]
        }

        return null
    }
    const [playerFour, setPlayerFour] = useState()
    const [playerThree, setPlayerThree] = useState()
    const [playerTwo, setPlayerTwo] = useState()
    const [playerFive, setPlayerFive] = useState()
    const [playerSix, setPlayerSix] = useState()
    const [playerOne, setPlayerOne] = useState()

    useEffect(()=>{
        if (teamAorB === 1 && activePlayersAContext){
            setPlayerFour(getPlayer(4))
            setPlayerFive(getPlayer(5))
            setPlayerOne(getPlayer(1))
            setPlayerSix(getPlayer(6))
            setPlayerThree(getPlayer(3))
            setPlayerTwo(getPlayer(2))
        }
    },[activePlayersAContext])

    useEffect(()=>{
        if (teamAorB === 2 && activePlayersBContext){
            setPlayerFour(getPlayer(4))
            setPlayerFive(getPlayer(5))
            setPlayerOne(getPlayer(1))
            setPlayerSix(getPlayer(6))
            setPlayerThree(getPlayer(3))
            setPlayerTwo(getPlayer(2))
        }
    },[activePlayersBContext])

    const showPlayerInfo = (_player) => {
        if (_player && _player.label)
            return _player?.label 
        else if (_player && _player.number)
            return _player?.number 
        else {
            if (_player?.player?.first_name && _player?.player?.last_name)
                return _player?.player?.first_name[0]+_player?.player?.last_name[0]
            else
                return ''
        } 
    }
    
    return (
        
        <div className='flex flex-column w-5rem h-5rem'>
            <div className='flex h-2rem'>
                <div className='col-4 border-1 flex align-items-center justify-content-center h-full'>
                    {showPlayerInfo(playerFour)}
                </div>
                <div className='col-4 border-1 flex align-items-center justify-content-center h-full'>
                    {showPlayerInfo(playerThree)}
                </div>
                <div className='col-4 border-1 flex align-items-center justify-content-center h-full'>
                    {showPlayerInfo(playerTwo)}
                </div>
            </div>
            <div className='flex h-3rem'>
                <div className='col-4 border-1 flex align-items-center justify-content-center h-full'>
                    {showPlayerInfo(playerFive)}
                </div>
                <div className='col-4 border-1 flex align-items-center justify-content-center h-full'>
                    {showPlayerInfo(playerSix)}
                </div>
                <div className='col-4 border-1 flex align-items-center justify-content-center bg-bluegray-300 h-full'>
                    {showPlayerInfo(playerOne)}
                </div>
            </div>
        </div>
    )
}

export default MiniCourtRotationComponent