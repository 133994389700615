import React, { useContext, useEffect, useState } from 'react'
import StatisticsContext from '../StatisticsContext'
import { actionsUtils } from '../Utils/ActionsUtils'
import { qualitiesAttack, qualitiesBlock, qualitiesDig, qualitiesReception, qualitiesServe, qualitiesSet } from '../Utils/QualitiesUtils'
import { Checkbox } from 'primereact/checkbox'
import { Accordion, AccordionTab } from 'primereact/accordion';
import RallyActionsComponent from './RallyActionsComponent'
import { toast } from 'react-toastify'
import { Button } from 'primereact/button'
import { PiArrowClockwise } from 'react-icons/pi'
import MiniCourtRotationComponent from './MiniCourtRotationComponent'
        
        

function PracticeStatisticsSideComponent({isGameMode}) {

    const {gridData, playersA, setPlayersA, rotationA, activePlayersAContext, setActivePlayersAContext, activePlayersBContext, setActivePlayersBContext, 
        currentRallyContext, setCurrentRallyContext, setMatchContext, handleRotationA} = useContext(StatisticsContext)

    const [currentPlayerAction, setCurrentPlayerAction] = useState();
    const [selectedPlayer, setSelectedPlayer] = useState()
    const [selectedAction, setSelectedAction] = useState()
    const [isServeReceptionLayout, setIsServeReceptionLayout] = useState(true)
    const [isServeActive, setIsServeActive] = useState(true)
    const [isReceptionActive, setIsReceptionActive] = useState(true)
    const [isAttackActive, setIsAttackActive] = useState(true)
    const [isSetActive, setIsSetActive] = useState(true)
    const [isBlockActive, setIsBlockActive] = useState(true)
    const [isDigActive, setIsDigActive] = useState(true)

    const [playerIndexSelected, setPlayerIndexSelected] = useState()
    const [initialLocalData, setInitialLocalData] = useState({})

    useEffect(()=>{
        gridData && setPlayersA(gridData.playersTeamA.map((e)=>{
            return (
                {
                    ...e,
                    label: e.number ? e.number : '',
                }
            )
        }))
        handleInitialData()
    },[gridData])

    const handleInitialData = async () => {
        if (!gridData) return 
        const statsInitialData = await JSON.parse(localStorage.getItem("statsInitialData"));

        const initialDataIndex = statsInitialData?.length > 0 ? statsInitialData?.findIndex(e=>e.matchAccessKey === gridData?.matchAccessKey) : -1
        if (initialDataIndex >= 0) {
            setInitialLocalData(statsInitialData[initialDataIndex])
            statsInitialData[initialDataIndex].activePlayers && setActivePlayersAContext(statsInitialData[initialDataIndex].activePlayers)
            statsInitialData[initialDataIndex].currentRallyContext && setCurrentRallyContext(statsInitialData[initialDataIndex].currentRallyContext)
        } else {
            setInitialLocalData({matchAccessKey: gridData?.matchAccessKey})
        }
    }

    useEffect(()=>{
        var _statsInitialDataList = JSON.parse(localStorage.getItem("statsInitialData"));
        const initialDataIndex = _statsInitialDataList?.length > 0 ? _statsInitialDataList?.findIndex(e=>e.matchAccessKey === initialLocalData?.matchAccessKey) : -1
        if (initialDataIndex === -1) 
        {
            _statsInitialDataList = _statsInitialDataList?.length > 0 ? [..._statsInitialDataList, initialLocalData] : [initialLocalData]
            localStorage.setItem("statsInitialData", JSON.stringify(_statsInitialDataList))
        } else {
            const index = _statsInitialDataList?.findIndex(e=>e.matchAccessKey === initialLocalData?.matchAccessKey)
            _statsInitialDataList[index] = initialLocalData
            localStorage.setItem("statsInitialData", JSON.stringify(_statsInitialDataList))
        }
        // localStorage.setItem("statsInitialData", JSON.stringify(null))
    },[initialLocalData])

    useEffect(()=>{
        if (currentPlayerAction) {
            setCurrentRallyContext([...currentRallyContext, currentPlayerAction])
            setCurrentPlayerAction()
        }
    },[currentPlayerAction])

    const changePlayerPosition = (_pos) => {
        var newPosition = _pos - 1
        newPosition = newPosition === 0 ? 6 : newPosition
        return newPosition
    }

    useEffect(()=>{
        if (playersA) {
            var tempPlayers = [...playersA]
            setPlayersA(tempPlayers.map((e)=>{
                return (
                    {
                        ...e,
                        position: e.position > 0 ? changePlayerPosition(e.position) : ''
                    }
                )
            }))
        }
    },[rotationA])

    useEffect(()=>{
        selectedAction && selectedPlayer && finishAction()
    },[selectedAction, selectedPlayer])

    const finishAction = () => {
        const currentPlayerAction = {
            player: selectedPlayer, 
            player_id: selectedPlayer.player.id, 
            playerName: selectedPlayer.number, 
            action_number: selectedAction.action.number+"", 
            action: selectedAction.action.id, 
            position_action: null, 
            quality_value: selectedAction.quality.number, 
            quality: selectedAction.quality.id, 
            rotation: rotationA, 
            position: Number(selectedPlayer.position),
            team: gridData.teamA, 
            set_match: setMatchContext
        }
        setCurrentPlayerAction(currentPlayerAction)
        refresh()
    }

    const refresh = async () => {
        setSelectedAction()
        setSelectedPlayer()
        setPlayerIndexSelected()
    }
    
    const handleSelectedPlayer = (element) => {
        var _players = [...playersA]
        const playerIdx = _players.findIndex(e=>Number(e.number) === Number(element.number))

        if (!element.position && !element.courtPosition){
            var _position = 0
            for (let position = 1; position <= 6; position++){
                const indexPosition = _players.findIndex(e=>Number(e.position) === Number(position))
                if (indexPosition === -1) {
                    _position = position
                    break
                };
            }
            _players[playerIdx].position = Number(_position)
            _players[playerIdx].courtPosition = Number(_position)
            setPlayersA(_players)
        } else {
            _players[playerIdx].position = ''
            _players[playerIdx].courtPosition = ''
            setPlayersA(_players)
        }

        setPlayerIndexSelected(element.player.access_key)
        setSelectedPlayer(element)
    }
    
    const handleSelectedAction = (action, quality) => {
        if (!selectedPlayer ){
            toast.info('No player selected.')
            return
        }
        (action.number === "ser" || action.number === "rec") && setIsServeReceptionLayout(false)
        setSelectedAction({action, quality})
    }

    useEffect(()=>{
        currentRallyContext?.length === 0 && setIsServeReceptionLayout(true)
        var _initialLocalData = {...initialLocalData}
        _initialLocalData.currentRallyContext = currentRallyContext
        setInitialLocalData(_initialLocalData)
    },[currentRallyContext])

    const handleActivePlayersA = (e) => {
        let _activePlayers = [...activePlayersAContext];

        if (e.checked)
            _activePlayers.push(e.value);
        else
            _activePlayers.splice(_activePlayers.indexOf(e.value), 1);
        setActivePlayersAContext(_activePlayers);

        var _initialLocalData = {...initialLocalData}
        _initialLocalData.activePlayers = _activePlayers
        setInitialLocalData(_initialLocalData);        
    }

    const handlePlayerBoxColor = (skill) => {
        if (skill === 10) return 'border-blue-400 bg-blue-100' 
        if (skill === 11) return 'border-bluegray-400 bg-bluegray-100'
        if (skill === 9) return 'border-yellow-400 bg-yellow-100'
        if (skill === 14) return 'border-purple-400 bg-purple-100'
        if (skill === 12) return 'border-pink-400 bg-pink-100'
    }
    
    return (
        <div className='flex flex-column gap-2' 
        style={{
            WebkitUserSelect: 'none', /* Safari */        
            MozUserSelect: 'none', /* Firefox */
            msUserSelect: 'none', /* IE10+/Edge */
            userSelect: 'none', /* Standard */}}>

            <Accordion multiple activeIndex={[0]}>
                <AccordionTab header="All Players">
                    <div className='flex flex-wrap gap-2 justify-content-between'>
                        <div className='flex flex-wrap gap-2 justify-content-start'>
                            {playersA?.filter(e=>e.player.skill === 10).map((element, key)=>(
                                <div key={key} onClick={()=>handleSelectedPlayer(element)}
                                className={`flex flex-column gap-2 p-2 border-2 border-round w-6rem 
                                ${playerIndexSelected === element.player.access_key ? 'border-green-400 bg-green-200' : handlePlayerBoxColor(element.player.skill)}`}>
                                    <div className='text-xl flex gap-1 align-items-center font-bold'>
                                        <Checkbox value={element.player.id} 
                                        checked={activePlayersAContext?.findIndex(e=> e === element.player.id) >= 0} 
                                        onChange={handleActivePlayersA} />
                                        {element.number < 10 ? `0${element.number}` : element.number}
                                    </div>
                                    <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                                        {element.player.first_name} {element.player.last_name}
                                    </div>
                                </div> 
                                ))
                            }
                        </div>
                        <div className='flex flex-wrap gap-2 justify-content-start'>
                            {playersA?.filter(e=>e.player.skill === 11).map((element, key)=>(
                                <div key={key} onClick={()=>handleSelectedPlayer(element)}
                                className={`flex flex-column gap-2 p-2 border-2 border-round w-6rem 
                                ${playerIndexSelected === element.player.access_key ? 'border-green-400 bg-green-200' : handlePlayerBoxColor(element.player.skill)}`}>
                                    <div className='text-xl flex gap-1 align-items-center font-bold'>
                                        <Checkbox value={element.player.id} 
                                        checked={activePlayersAContext.findIndex(e=> e === element.player.id) >= 0} 
                                        onChange={handleActivePlayersA} />
                                        {element.number < 10 ? `0${element.number}` : element.number}
                                    </div>
                                    <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                                        {element.player.first_name} {element.player.last_name}
                                    </div>
                                </div> 
                                ))
                            }
                        </div>
                        <div className='flex flex-wrap gap-2 justify-content-start'>
                            {playersA?.filter(e=>e.player.skill === 9).map((element, key)=>(
                                <div key={key} onClick={()=>handleSelectedPlayer(element)}
                                className={`flex flex-column gap-2 p-2 border-2 border-round w-6rem 
                                ${playerIndexSelected === element.player.access_key ? 'border-green-400 bg-green-200' : handlePlayerBoxColor(element.player.skill)}`}>
                                    <div className='text-xl flex gap-1 align-items-center font-bold'>
                                        <Checkbox value={element.player.id} 
                                        checked={activePlayersAContext.findIndex(e=> e === element.player.id) >= 0} 
                                        onChange={handleActivePlayersA} />
                                        {element.number < 10 ? `0${element.number}` : element.number}
                                    </div>
                                    <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                                        {element.player.first_name} {element.player.last_name}
                                    </div>
                                </div> 
                                ))
                            }
                        </div>
                        <div className='flex flex-wrap gap-2 justify-content-start'>
                            {playersA?.filter(e=>e.player.skill === 14).map((element, key)=>(
                                <div key={key} onClick={()=>handleSelectedPlayer(element)}
                                className={`flex flex-column gap-2 p-2 border-2 border-round w-6rem 
                                ${playerIndexSelected === element.player.access_key ? 'border-green-400 bg-green-200' : handlePlayerBoxColor(element.player.skill)}`}>
                                    <div className='text-xl flex gap-1 align-items-center font-bold'>
                                        <Checkbox value={element.player.id} 
                                        checked={activePlayersAContext.findIndex(e=> e === element.player.id) >= 0} 
                                        onChange={handleActivePlayersA} />
                                        {element.number < 10 ? `0${element.number}` : element.number}
                                    </div>
                                    <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                                        {element.player.first_name} {element.player.last_name}
                                    </div>
                                </div> 
                                ))
                            }
                        </div>
                        <div className='flex flex-wrap gap-2 justify-content-start'>
                            {playersA?.filter(e=>e.player.skill === 12).map((element, key)=>(
                                <div key={key} onClick={()=>handleSelectedPlayer(element)}
                                className={`flex flex-column gap-2 p-2 border-2 border-round w-6rem 
                                ${playerIndexSelected === element.player.access_key ? 'border-green-400 bg-green-200' : handlePlayerBoxColor(element.player.skill)}`}>
                                    <div className='text-xl flex gap-1 align-items-center font-bold'>
                                        <Checkbox value={element.player.id} 
                                        checked={activePlayersAContext.findIndex(e=> e === element.player.id) >= 0} 
                                        onChange={handleActivePlayersA} />
                                        {element.number < 10 ? `0${element.number}` : element.number}
                                    </div>
                                    <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                                        {element.player.first_name} {element.player.last_name}
                                    </div>
                                </div> 
                                ))
                            }
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header="Selected Players">
                    <div className='flex gap-2'>
                        <div className='flex flex-1 flex-wrap gap-2 justify-content-start'>
                            {playersA?.filter(e=>activePlayersAContext.findIndex(i=>i === e.player.id) >= 0)
                            .sort((a, b) => a.player.order - b.player.order).map((element, key)=>(
                                <div key={key} onClick={()=>handleSelectedPlayer(element)}
                                className={`flex flex-column gap-2 p-2 border-2 border-round w-6rem 
                                ${playerIndexSelected === element.player.access_key ? 'border-green-400 bg-green-200' : handlePlayerBoxColor(element.player.skill)}`}>
                                    <div className='text-xl flex gap-1 align-items-center font-bold'>
                                        {element.number < 10 ? `0${element.number}` : element.number}
                                    </div>
                                    <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                                        {element.player.first_name} {element.player.last_name}
                                    </div>
                                </div> 
                                ))
                            }
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>







            {isGameMode ? isServeReceptionLayout ? 
                <div className='flex gap-2 w-full'>
                    <div className='flex flex-column p-2 gap-2 align-items-center w-full'>
                        <div className='font-bold flex gap-1'>
                            <Checkbox value={isServeActive} 
                            checked={isServeActive} 
                            onChange={()=>setIsServeActive(!isServeActive)} />
                            <label>SER</label>
                        </div>
                        <div className={`p-2 gap-2 align-items-center w-full ${isServeActive ? 'flex flex-column ' : 'hidden'}`}>
                            <div onClick={()=>handleSelectedAction(actionsUtils.serve, qualitiesServe.point)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-400'>
                                A
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.serve, qualitiesServe.freeBall)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-200'>
                                B
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.serve, qualitiesServe.setPin)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-yellow-200'>
                                C
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.serve, qualitiesServe.setMiddle)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-200'>
                                D
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.serve, qualitiesServe.error)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-400'>
                                E
                            </div>
                        </div>
                    </div>
                    <div className=' flex flex-column p-2 gap-2 align-items-center w-full'>
                        <div className='font-bold flex gap-1'>
                            <Checkbox value={isReceptionActive} 
                            checked={isReceptionActive} 
                            onChange={()=>setIsReceptionActive(!isReceptionActive)} />
                            <label>REC</label>
                        </div>
                        <div className={`p-2 gap-2 align-items-center w-full ${isReceptionActive ? 'flex flex-column ' : 'hidden'}`}>
                            <div onClick={()=>handleSelectedAction(actionsUtils.reception, qualitiesReception.four)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-400'>
                                A
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.reception, qualitiesReception.three)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-200'>
                                B
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.reception, qualitiesReception.two)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-yellow-200'>
                                C
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.reception, qualitiesReception.one)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-200'>
                                D
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.reception, qualitiesReception.zero)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-400'>
                                E
                            </div>
                        </div>
                    </div>
                </div> :
                <div className='flex gap-2 w-full'>
                    <div className=' flex flex-column p-2 gap-2 align-items-center w-full'>
                        <div className='font-bold flex gap-1'>
                            <Checkbox value={isAttackActive} 
                            checked={isAttackActive} 
                            onChange={()=>setIsAttackActive(!isAttackActive)} />
                            <label>ATT</label>
                        </div>

                        <div className={`p-2 gap-2 align-items-center w-full ${isAttackActive ? 'flex flex-column ' : 'hidden'}`}>
                            <div onClick={()=>handleSelectedAction(actionsUtils.attack, qualitiesAttack.two)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-400'>
                                A
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.attack, qualitiesAttack.one)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-yellow-200'>
                                B
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.attack, qualitiesAttack.zero)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-400'>
                                C
                            </div>
                        </div>
                    </div>
                    <div className=' flex flex-column p-2 gap-2 align-items-center w-full'>
                        <div className='font-bold flex gap-1'>
                            <Checkbox value={isSetActive} 
                            checked={isSetActive} 
                            onChange={()=>setIsSetActive(!isSetActive)} />
                            <label>SET</label>
                        </div>

                        <div className={`p-2 gap-2 align-items-center w-full ${isSetActive ? 'flex flex-column ' : 'hidden'}`}>
                            <div onClick={()=>handleSelectedAction(actionsUtils.set, qualitiesSet.two)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-400'>
                                A
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.set, qualitiesSet.one)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-yellow-200'>
                                B
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.set, qualitiesSet.zero)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-400'>
                                C
                            </div>
                        </div>
                    </div>
                    <div className=' flex flex-column p-2 gap-2 align-items-center w-full'>
                        <div className='font-bold flex gap-1'>
                            <Checkbox value={isBlockActive} 
                            checked={isBlockActive} 
                            onChange={()=>setIsBlockActive(!isBlockActive)} />
                            <label>BLO</label>
                        </div>

                        <div className={`p-2 gap-2 align-items-center w-full ${isBlockActive ? 'flex flex-column ' : 'hidden'}`}>
                            <div onClick={()=>handleSelectedAction(actionsUtils.block, qualitiesBlock.two)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-400'>
                                A
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.block, qualitiesBlock.one)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-yellow-200'>
                                B
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.block, qualitiesBlock.zero)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-400'>
                                C
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-column p-2 gap-2 align-items-center w-full'>
                        <div className='font-bold flex gap-1'>
                            <Checkbox value={isDigActive} 
                            checked={isDigActive} 
                            onChange={()=>setIsDigActive(!isDigActive)} />
                            <label>DIG</label>
                        </div>

                        <div className={`p-2 gap-2 align-items-center w-full ${isDigActive ? 'flex flex-column ' : 'hidden'}`}>
                            <div onClick={()=>handleSelectedAction(actionsUtils.dig, qualitiesDig.two)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-400'>
                                A
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.dig, qualitiesDig.one)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-yellow-200'>
                                B
                            </div>
                            <div onClick={()=>handleSelectedAction(actionsUtils.dig, qualitiesDig.zero)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-400'>
                                C
                            </div>
                        </div>
                    </div>
                </div> :
                <div className='flex gap-2 w-full'>
                    <div className=' flex flex-column p-2 gap-2 align-items-center w-full'>
                        <div className='font-bold'>SER</div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.serve, qualitiesServe.point)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-400'>
                            A
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.serve, qualitiesServe.freeBall)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-200'>
                            B
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.serve, qualitiesServe.setPin)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-yellow-200'>
                            C
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.serve, qualitiesServe.setMiddle)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-200'>
                            D
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.serve, qualitiesServe.error)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-400'>
                            E
                        </div>
                    </div>
                    <div className=' flex flex-column p-2 gap-2 align-items-center w-full'>
                        <div className='font-bold'>REC</div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.reception, qualitiesReception.four)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-400'>
                            A
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.reception, qualitiesReception.three)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-200'>
                            B
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.reception, qualitiesReception.two)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-yellow-200'>
                            C
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.reception, qualitiesReception.one)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-200'>
                            D
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.reception, qualitiesReception.zero)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-400'>
                            E
                        </div>
                    </div>
                    <div className=' flex flex-column p-2 gap-2 align-items-center w-full'>
                        <div className='font-bold'>ATT</div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.attack, qualitiesAttack.two)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-400'>
                            A
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.attack, qualitiesAttack.one)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-yellow-200'>
                            B
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.attack, qualitiesAttack.zero)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-400'>
                            C
                        </div>
                    </div>
                    <div className=' flex flex-column p-2 gap-2 align-items-center w-full'>
                        <div className='font-bold'>SET</div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.set, qualitiesSet.two)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-400'>
                            A
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.set, qualitiesSet.one)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-yellow-200'>
                            B
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.set, qualitiesSet.zero)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-400'>
                            C
                        </div>
                    </div>
                    <div className=' flex flex-column p-2 gap-2 align-items-center w-full'>
                        <div className='font-bold'>BLO</div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.block, qualitiesBlock.two)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-400'>
                            A
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.block, qualitiesBlock.one)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-yellow-200'>
                            B
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.block, qualitiesBlock.zero)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-400'>
                            C
                        </div>
                    </div>
                    <div className='flex flex-column p-2 gap-2 align-items-center w-full'>
                        <div className='font-bold'>DIG</div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.dig, qualitiesDig.two)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-green-400'>
                            A
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.dig, qualitiesDig.one)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-yellow-200'>
                            B
                        </div>
                        <div onClick={()=>handleSelectedAction(actionsUtils.dig, qualitiesDig.zero)} className='h-4rem w-full align-content-center text-center text-2xl border-1 border-round bg-red-400'>
                            C
                        </div>
                    </div>
                </div>
            }

            <div>
                <RallyActionsComponent />
            </div>
        </div>
    )

}

export default PracticeStatisticsSideComponent