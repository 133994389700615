import React, { useContext, useState } from 'react'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { Divider } from 'primereact/divider'
import { InputNumber } from 'primereact/inputnumber'
import { updateMatchPlayerNumber } from '../../../../../services/StatsService'
import StatisticsContext from '../../StatisticsContext'

function ManageTeamButtonsComponent({players, setPlayers, activePlayersContext, setActivePlayersContext}) {

    const {gridData} = useContext(StatisticsContext)

    const handlePlayerBoxColor = (skill) => {
        if (skill === 10) return 'border-blue-400 bg-blue-100' 
        if (skill === 11) return 'border-bluegray-400 bg-bluegray-100'
        if (skill === 9) return 'border-yellow-400 bg-yellow-100'
        if (skill === 14) return 'border-purple-400 bg-purple-100'
        if (skill === 12) return 'border-pink-400 bg-pink-100'
    }

    const handleActivePlayers = (element) => {
        // Create new copies of the state to avoid mutation
        const _activePlayers = [...activePlayersContext];
        const _players = [...players];
    
        const playerIdx = _players.findIndex(player => Number(player.player.id) === Number(element.value.player.id));
        if (playerIdx === -1) return
        const player = _players[playerIdx];
        let updatedPlayer = null
        
        // If player is checked
        if (element.checked) {
            if (!player?.position && !player?.courtPosition) {
                let _position = 0;
                // Find the next available position
                for (let position = 1; position <= 6; position++) {
                    const indexPosition = _players.findIndex(e => Number(e.position) === position);
                    if (indexPosition === -1) {
                        _position = position;
                        break;
                    }
                }
    
                // Update player position
                updatedPlayer = { ...player, position: _position, courtPosition: _position };
            } else {
                // If already has a position, clear it
                updatedPlayer = { ...player, position: '', courtPosition: '' };
            }
            _players[playerIdx] = updatedPlayer;
            _activePlayers.push(updatedPlayer);
        } else {
            // If unchecked, remove from active players
            const indexToRemove = _activePlayers.findIndex(e=> e.player.id === element.value.player.id);
            if (indexToRemove !== -1) {
                _activePlayers.splice(indexToRemove, 1);
            }
            // If already has a position, clear it
            updatedPlayer = { ...player, position: '', courtPosition: '' };
            _players[playerIdx] = updatedPlayer;
        }
        setPlayers([..._players]); // Use a new array to set state
    
        setActivePlayersContext([..._activePlayers]);
    };

    const handleChangePlayerNumber = (_value, _index) => {
        let _activePlayers = [...activePlayersContext]
        let _players = [...players]

        const _activeIndex = _activePlayers.findIndex(e=>e.player.access_key === _players[_index].player.access_key)
        if (_activeIndex >= 0){
            _activePlayers[_activeIndex].number = Number(_value)
            _activePlayers[_activeIndex].label = Number(_value)
            setActivePlayersContext(_activePlayers)
        }


        _players[_index].number = Number(_value)
        _players[_index].label = Number(_value)
        setPlayers(_players)
    }
    
    const handleSavePlayerNumber = (match_id, player_id, number) => {
        updateMatchPlayerNumber(match_id, player_id, Number(number))
    }

    return (
        <div>
            {
                players && 
                <div className='flex flex-wrap gap-1 md:gap-2 justify-content-start'>
                    {players?.map((element, key)=>(
                    <div key={key}>
                        <div className={`flex flex-column mb-2 ${key < players?.length-1 && element.player.skill !== players[key+1].player.skill && 'mr-4'}`}>
                            <div className='flex pb-1 justify-content-between align-items-center'>
                                <div className='text-l md:text-4xl'>#</div>
                                <InputNumber inputClassName='w-3rem md:w-4rem text-xs md:text-base h-2rem md:h-3rem' onBlur={(e)=>handleSavePlayerNumber(gridData.matchId, element.player.id, e.target.value)} 
                                value={element.number} onChange={(e)=>handleChangePlayerNumber(e.value, key)} />
                            </div>
                            <div key={key}
                                className={`flex flex-column gap-1 md:gap-2 p-1 md:p-2 border-2 border-round w-4rem md:w-6rem ${handlePlayerBoxColor(element.player.skill)}`}>
                                    <div className='text-sm md:text-xl flex gap-1 align-items-center font-bold'>
                                        <Checkbox value={element} 
                                        checked={activePlayersContext?.findIndex(e=> e?.player?.id === element?.player?.id) >= 0} 
                                        onChange={handleActivePlayers} />
                                        {element.number < 10 ? `0${element.number}` : element.number}
                                    </div>
                                    <div className='text-sm md:text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                                        {element.player.first_name} {element.player.last_name}
                                    </div>
                                </div> 
                        </div>
                    </div>))
                    }
                </div>
            }
        </div>
    )
}

export default ManageTeamButtonsComponent