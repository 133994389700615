import React, { useContext} from 'react'
import StatisticsContext from '../StatisticsContext'
import GameGridStatisticsComponent from './GameGridStatisticsComponent'
import SpeachStatisticsComponent from './SpeachStatisticsComponent'


function GameStatisticsComponent() {

    const {speachMode} = useContext(StatisticsContext)
    

    return (
        <div>
            {speachMode ? 
                <SpeachStatisticsComponent />
            :
            <GameGridStatisticsComponent />}
        </div>
    )

}

export default GameStatisticsComponent