import React, { useState } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';

const YouTubeUploaderComponent = () => {
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState('');
    const [uploading, setUploading] = useState(false);
  
    const handleFileChange = (e) => {
      setFile(e.target.files[0]);
    };
  
    const handleUpload = async () => {
      if (!file) {
        alert('Please select a file to upload.');
        return;
      }
  
      setUploading(true);
  
      try {
        // Step 1: Get the OAuth URL from the backend
        const { data } = await axios.get('http://localhost:5000/auth/url');
        const authUrl = data.authUrl;
  
        // Step 2: Redirect the user to the OAuth URL
        window.location.href = authUrl;
  
        // Step 3: After user consent, handle the OAuth callback
        const code = new URLSearchParams(window.location.search).get('code');
        if (code) {
          // Convert the file to base64
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async () => {
            const base64File = reader.result.split(',')[1];
  
            // Step 4: Send the file and metadata to the backend
            const response = await axios.post('http://localhost:5000/auth/callback', {
              code,
              file: base64File,
              title,
              description,
              tags,
            });
  
            console.log('Upload successful:', response.data);
            alert('Video uploaded successfully!');
          };
        }
      } catch (error) {
        console.error('Error uploading video:', error);
        alert('Error uploading video.');
      } finally {
        setUploading(false);
      }
    };
  
    return (
      <div>
        <FileUpload type="file" onChange={handleUpload} chooseLabel='Select Video' uploadLabel='Upload Video' accept='video/*' />
      </div>
    );
  };

export default YouTubeUploaderComponent;