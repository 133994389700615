import React, { useContext, useEffect, useState } from 'react'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { InputText } from 'primereact/inputtext';
import { Editor } from 'primereact/editor';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/AuthContext/AuthContext';
import { MultiSelect } from 'primereact/multiselect';
import YouTubeUploaderComponent from '../../../components/YouTubeUploaderComponent';

function CreateDrill() {

    const navigate = useNavigate();
    const {setValue, register, handleSubmit, clearErrors, getValues, watch, formState: {errors}, reset} = useForm();

    const {userUpdateProfile, userAuth} = useContext(AuthContext);
    const [url, setUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [description, setDescription] = useState('')
    const [playerPositionsSelected, setPlayerPositionsSelected] = useState([])
    const [playerPositions, setPlayerPositions] = useState([
        {id: 1, label: 'Outside',},
        {id: 3, label: 'Right side',},
        {id: 3, label: 'Middle blocker',},
        {id: 3, label: 'Setter',},
        {id: 3, label: 'Libero',},
    ])

    const [taticalGoalsSelected, setTaticalGoalsSelected] = useState()
    const [taticalGoals, setTaticalGoals] = useState([
        {id: 1, label: 'Attack',},
        {id: 3, label: 'First ball sideout',},
        {id: 3, label: 'Transition',},
        {id: 3, label: 'Out-of-system',},
        {id: 3, label: 'Serve',},
        {id: 3, label: 'Serve receive',},
        {id: 3, label: 'Defense',},
    ])

    const [technicalGoalsSelected, setTechnicalGoalsSelected] = useState()
    const [technicalGoals, setTechnicalGoals] = useState([
        {id: 1, label: 'Attack',},
        {id: 3, label: 'First ball sideout',},
        {id: 3, label: 'Transition',},
        {id: 3, label: 'Out-of-system',},
        {id: 3, label: 'Serve',},
        {id: 3, label: 'Serve receive',},
        {id: 3, label: 'Defense',},
    ])

    useEffect(()=>{
        reset({
            title: '',
            url: '',
            description: '',
        })
    },[reset])

    const onSubmit = async (data) => {
        setIsLoading(true)
        
        setIsLoading(false)
    }

    const onError = (errors) => {
    }

    const handlePlayerPositions = (e) => {
        setPlayerPositionsSelected(e.value)
    }

    const handleTechnicalGoals = (e) => {
        setTechnicalGoalsSelected(e.value)
    }

    return (
    <AppBodyComponent activeMenuNumber={2}>
        <div className="form--container">
            <form onSubmit={handleSubmit(onSubmit, onError)} className='text--regular--normal'>
                <div className="form--row">
                    <div className="form--error-fieldset">
                        <InputText type='text' {...register("title", {required: 'Required', maxLength: {value: 20, message: 'Max 50 characters'}})} placeholder='Drill Title' 
                        style={errors.title && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.title?"true":"false"} />
                        {errors.title && <span className='error-text'>{errors.title?.message}</span>}
                    </div>
                </div>
                <div className="form--row">
                    <div className="form--error-fieldset">
                        <MultiSelect options={playerPositions} onChange={handlePlayerPositions} value={playerPositionsSelected} placeholder={`Player's Positions Involved`} />
                        {errors.username && <span className='error-text'>{errors.username?.message}</span>}
                    </div>
                </div>
                <div className="form--row">
                    <div className="form--error-fieldset">
                        <MultiSelect placeholder='Technical Goals' options={technicalGoals} value={technicalGoalsSelected} onChange={handleTechnicalGoals} />
                        {errors.username && <span className='error-text'>{errors.username?.message}</span>}
                    </div>
                </div>
                <div className="form--row">
                    <div className="form--error-fieldset">
                        <MultiSelect options={taticalGoals} {...register("taticalGoal", {required: "Required", pattern: {value: "/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/", message:'Invlaid username'}})} 
                        placeholder='Tatical Goals' style={errors.username && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.username?"true":"false"} />
                        {errors.username && <span className='error-text'>{errors.username?.message}</span>}
                    </div>
                </div>
                <div className="form--row">
                    <div className="form--error-fieldset">
                        <MultiSelect {...register("username", {required: "Required", pattern: {value: "/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/", message:'Invlaid username'}})} 
                        placeholder='Physical Goals' style={errors.username && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.username?"true":"false"} />
                        {errors.username && <span className='error-text'>{errors.username?.message}</span>}
                    </div>
                </div>
                <div className="form--row">
                    <div className="form--error-fieldset">
                        <YouTubeUploaderComponent />
                    </div>
                </div>
                <div className="form--row">
                    <div className="form--fieldset">
                        <Editor value={description} style={{height: '220px'}} placeholder='Drill Description'></Editor>
                    </div>
                </div>
                <div className="form--action_row">
                    <Button loading={isLoading} type="submit">Save</Button>
                </div>
            </form>
        </div>
    </AppBodyComponent>
  )
}

export default CreateDrill