import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useEffect, useState } from 'react'
import StatisticsContext from '../StatisticsContext';
        
import { PiTrash } from 'react-icons/pi';

function RallyListComponent({rallyItem, index}) {
    const {playersA, playersB, gridData, setCurrentRallyContext, currentRallyContext} = useContext(StatisticsContext)
    const [color, setColor] = useState('')
    const [visible, setVisible] = useState(false)

    const actions = [
        {
            action: 1,
            action_number: "ser"
        },
        {
            action: 2,
            action_number: "rec"
        },
        {
            action: 3,
            action_number: "set"
        },
        {
            action: 4,
            action_number: "att"
        },
        {
            action: 5,
            action_number: "blo"
        },
        {
            action: 6,
            action_number: "dig"
        },
    ]

    const qualitiesFive = [
        {
            quality: 5,
            quality_value: "A"
        },
        {
            quality: 4,
            quality_value: "B"
        },
        {
            quality: 3,
            quality_value: "C"
        },
        {
            quality: 2,
            quality_value: "D"
        },
        {
            quality: 1,
            quality_value: "E"
        },
    ]

    const qualitiesThree = [
        {
            quality: 3,
            quality_value: "A"
        },
        {
            quality: 2,
            quality_value: "B"
        },
        {
            quality: 1,
            quality_value: "C"
        }
    ]

    const positions = [1,2,3,4,5,6]

    useEffect(()=>{
        handleSetColor();
    },[rallyItem])


    const handleSetColor = () => {
        if (rallyItem?.action === 1 || rallyItem?.action === 2){ //ser || rec
            if (rallyItem?.quality === 5) setColor(qualitiesFive.four.color) //green
            if (rallyItem?.quality === 4) setColor(qualitiesFive.three.color) //light green
            if (rallyItem?.quality === 3) setColor(qualitiesFive.two.color) //yellow
            if (rallyItem?.quality === 2) setColor(qualitiesFive.one.color) //orange
            if (rallyItem?.quality === 1) setColor(qualitiesFive.zero.color) //red
        }
        if (rallyItem?.action >= 3){ //set, att, blo, dig
            if (rallyItem?.quality === 3) setColor(qualitiesThree.two.color) //green
            if (rallyItem?.quality === 2) setColor(qualitiesThree.one.color) //yellow
            if (rallyItem?.quality === 1) setColor(qualitiesThree.zero.color) //red
        }
    }

    const removeAction = () => {
        var rally = [...currentRallyContext]
        rally = rally.filter((item, key)=> key !== index)
        setCurrentRallyContext(rally)
        setVisible(false)
    }

    const handleSave = () => {
        // var rally = [...currentRallyContext]
        // rally[index] = {
        //     ...rally[index],
        //     player_id: selectedPlayer?.player.player_id,
        //     playerName: selectedPlayer?.number, 
        //     action_number: selectedAction?.action_number, 
        //     action: selectedAction?.action, 
        //     position_action: selectedPositionAction && selectedPositionAction, 
        //     quality_value: selectedQuality?.quality_value, 
        //     quality: selectedQuality?.quality, 
        //     rotation: selectedRotation && selectedRotation, 
        //     position: selectedPositionPlayer && selectedPositionPlayer,
        // }
        // setCurrentRallyContext(rally)     
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const markPoint = () =>{
        // setSelectedQuality({quality: 3, quality_value: 2})
    }

    const markError = () =>{
        // setSelectedQuality({quality: 1, quality_value: 0})
    }

    const qualityNumberToLetter = (_quality, _action) => {
        if (typeof(_quality) !== 'number') return _quality
        let _qualityLetter = ''
        if (_action === "ser" || _action === "rec"){
            _qualityLetter = _quality === 1 ? "E" : _quality === 2 ? "D" : _quality === 3 ? "C" : _quality === 4 ? "B" : _quality === 5
             ? "A" : ""
        } else {
            _qualityLetter = _quality === 1 ? "C" : _quality === 2 ? "B" : _quality === 3 ? "A" : ""
        }

        return _qualityLetter
    }

    const selectQualitiesOptions = (_action) => {
        return (_action === "ser" || _action === "rec") ? qualitiesFive : qualitiesThree
    }

    const handleSelectedPlayer = (_value, _index) => {
        console.log(_value)
        var rally = [...currentRallyContext]
        rally[_index] = {
            ...rally[_index],
            player_id: _value.player.id, 
            playerName: _value.number, 
            player: _value
        }
        setCurrentRallyContext(rally)     
    }

    const handleSelectedQuality = (_value, _index) => {
        var rally = [...currentRallyContext]
        rally[_index] = {
            ...rally[_index],
            quality: _value.quality, 
            quality_value: _value.quality_value, 
        }
        setCurrentRallyContext(rally)     
    }

    const handleSelectedPosition = (_value, _index) => {
        console.log(_value, _index)
        var rally = [...currentRallyContext]
        rally[_index] = {
            ...rally[_index],
            position_action: _value, 
        }
        setCurrentRallyContext(rally)     
    }

    const handleSelectedAction = (_value, _index) => {
        var rally = [...currentRallyContext]
        rally[_index] = {
            ...rally[_index],
            action_number: _value.action_number, 
            action: _value.action, 
        }
        setCurrentRallyContext(rally)     
    }

    const removeItemRally = (_index) => {
        var _rally = [...currentRallyContext]
        _rally = _rally.filter((item, key)=> key !== _index)
        setCurrentRallyContext(_rally)
    }

    return (
        <div className='flex flex-column'>
            {currentRallyContext?.map((item, key)=>(
                <div key={key} className={'flex flex-column mb-2'}>
                    {item.speach_text && <div className={`flex text-sm ${item.activeTeam === 1 ? 'justify-content-start' : 'justify-content-end'}`}>{item.speach_text}</div>}
                    <div className={`flex gap-2 ${item.activeTeam === 1 ? 'justify-content-start' : 'justify-content-end'}`}>
                        <div className='flex flex-column'>
                            <label htmlFor='player' className='text-sm'>Player</label>
                            <Dropdown onChange={(e)=>handleSelectedPlayer(e.value, key)} name="player" optionLabel='number'
                            options={item?.activeTeam === 1 ? playersA : playersB} value={item.player} />
                        </div>

                        <div className='flex flex-column'>
                            <label htmlFor='player' className='text-sm'>Action</label>
                            <Dropdown onChange={(e)=>handleSelectedAction(e.value, key)} name="action" optionLabel='action_number'
                            options={actions} value={{action: item.action, action_number: item.action_number}} />
                        </div>

                        <div className='flex flex-column'>
                            <label htmlFor='player' className='text-sm'>Zone</label>
                            <Dropdown onChange={(e)=>handleSelectedPosition(e.value, key)} name="positionAction"
                            options={positions} value={item.position_action} />
                        </div>

                        <div className='flex flex-column'>
                            <label htmlFor='player' className='text-sm'>Quality</label>
                            <Dropdown onChange={(e)=>handleSelectedQuality(e.value, key)} name="quality" optionLabel='quality_value' 
                            options={selectQualitiesOptions(item.action_number)}
                            value={{quality: item.quality, quality_value: qualityNumberToLetter(item.quality, item.action_number)}} />
                        </div>

                        <Button size='small' className={"p-2"} onClick={()=>removeItemRally(key)}><PiTrash size={18} /></Button>
                    </div> 
                </div>
            ))}

            {/* <div className='flex flex-column gap-1 mt-2'>
                {selectedAction?.action_number !== "ser" && selectedAction?.action_number !== "rec" ? 
                <div className={`${selectedQuality?.quality_value === 2 && 'bg-green-500'} 
                flex justify-content-center align-items-center h-3rem border-round border-green-500 border-1 cursor-pointer`}
                onClick={markPoint}>Point</div> : <div className='h-3rem'></div>}
                
                <div style={{backgroundColor: color, userSelect: 'none', WebkitUserSelect: 'none'}} className='border-round' onClick={()=>setVisible(true)}>
                    <div className="flex justify-content-center align-items-center text-s p-2 text-sm h-3rem font-bold">{rallyItem?.activeTeam === 1 ? 'A' : 'B'}: {gridData?.statisticsType !== 'player' && rallyItem?.player?.number} {rallyItem?.action_number}</div>
                </div>
                
                {selectedAction?.action_number !== "ser" && selectedAction?.action_number !== "rec" ? 
                <div className={`${selectedQuality?.quality_value === 0 && 'bg-red-500'} 
                flex justify-content-center align-items-center h-3rem border-round border-red-500 border-1 cursor-pointer`}
                onClick={markError}>Error</div> : <div className='h-3rem'></div>}
            </div> */}
        </div>
    )
}

export default RallyListComponent