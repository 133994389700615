import React, { useContext, useEffect, useRef, useState } from 'react'
import StatisticsContext from '../StatisticsContext'
import { createRally } from '../../../../services/StatsService'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import MenuButtonsComponent from './MenuButtonsComponent'
import { actionsUtils } from '../Utils/ActionsUtils'
import { toast } from 'react-toastify'
import ShowGameStats from '../ShowGameStats'
import {Divider} from 'primereact/divider'
import { Button } from 'primereact/button'
import { PiMicrophone, PiMicrophoneSlash } from 'react-icons/pi'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import useSound from 'use-sound'
import audioOn from './../../../../sounds/click_on.mp3'
import audioOff from './../../../../sounds/click_off.mp3'
import RallyListComponent from './RallyListComponent'


function SpeachStatisticsComponent() {

    const {gridData, playersA, setPlayersA, playersB, setPlayersB, rotationA, rotationB, setRotationA, setRotationB, activeTeam, 
        rotateA, rotateB, setRotateA, setRotateB, 
        setActiveTeam, activePlayersAContext, setActivePlayersAContext, activePlayersBContext, setActivePlayersBContext, 
        currentRallyContext, setCurrentRallyContext, handleRotationA, handleRotationB, currentActionRefContext, setMatchContext, 
        enabledAutoRotationContext} = useContext(StatisticsContext)
    const {userAuth} = useContext(AuthContext)
    const [gridLayout, setGridLayout] = useState(false)
    const [refreshReportData, setRefreshReportData] = useState(true)
    const { transcript, listening, resetTranscript } = useSpeechRecognition();
    const [teamListening, setTeamListening] = useState()
    const [transcriptProcessed, setTranscriptProcessed] = useState('')
    const [playOn] = useSound(audioOn);
    const [playOff] = useSound(audioOff);
    const [initialLocalData, setInitialLocalData] = useState({})

    useEffect(()=>{
        gridData && handleInitialData()
    },[gridData])

    useEffect(()=>{
        currentActionRefContext.current = actions[5]
        if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
            toast.info('Your browser does not support speech recognition.');
        }
    },[])

    useEffect(()=>{
        var _initialLocalData = {...initialLocalData}
        if (playersA){
            playersARef.current = playersA
            _initialLocalData.playersA = playersA
        }

        if (playersB){
            playersBRef.current = playersB
            _initialLocalData.playersB = playersB
        }

        if (activePlayersAContext){
            _initialLocalData.activePlayersA = activePlayersAContext
        }

        if (activePlayersBContext){
            _initialLocalData.activePlayersB = activePlayersBContext
        }
        setInitialLocalData(_initialLocalData);
    },[playersA, playersB, activePlayersAContext, activePlayersBContext])

    const handleSelectedPlayer = (element) => {
        currentPlayerRef.current = element
    }

    const handleInitialData = async () => {
        const statsInitialData = await JSON.parse(localStorage.getItem("statsInitialData"));
        const initialDataIndex = statsInitialData?.length > 0 ? statsInitialData?.findIndex(e=>e.matchAccessKey === gridData?.matchAccessKey) : -1
        if (initialDataIndex >= 0) {
            setInitialLocalData(statsInitialData[initialDataIndex])
            statsInitialData[initialDataIndex].playersA && setPlayersA(statsInitialData[initialDataIndex].playersA)
            statsInitialData[initialDataIndex].playersB && setPlayersB(statsInitialData[initialDataIndex].playersB)
            statsInitialData[initialDataIndex].activePlayersA && setActivePlayersAContext(statsInitialData[initialDataIndex].activePlayersA)
            statsInitialData[initialDataIndex].activePlayersB && setActivePlayersBContext(statsInitialData[initialDataIndex].activePlayersB)
            statsInitialData[initialDataIndex].currentRallyContext && setCurrentRallyContext(statsInitialData[initialDataIndex].currentRallyContext)
            statsInitialData[initialDataIndex].rotationA && setRotationA(statsInitialData[initialDataIndex].rotationA)
            statsInitialData[initialDataIndex].rotationB && setRotationB(statsInitialData[initialDataIndex].rotationB)
        } else {
            setInitialLocalData({matchAccessKey: gridData?.matchAccessKey})
            setPlayersA(gridData?.playersTeamA?.map((e)=>{
                var color = '';
                if (e.position == 4) color = "#0dc61d"
                if (e.position == 1) color = "#3af25b"
                if (e.position == 3) color = "#8398c4"
                if (e.position == 6) color = "#323035"
                if (e.position == 2) color = "#87663b"
                if (e.position == 5) color = "#b0dee5"
                return (
                    {
                        ...e,
                        color,
                        courtPosition: Number(e.position),
                        label: Number(e.number),
                    }
                )
            }))
            setPlayersB(gridData?.playersTeamB?.map((e)=>{
                var color = '';
                if (e.position == 4) color = "#0dc61d"
                if (e.position == 1) color = "#3af25b"
                if (e.position == 3) color = "#8398c4"
                if (e.position == 6) color = "#323035"
                if (e.position == 2) color = "#87663b"
                if (e.position == 5) color = "#b0dee5"
                return (
                    {
                        ...e,
                        color,
                        courtPosition: Number(e.position),
                        label: Number(e.number),
                    }
                )
            }))
        }
    }

    useEffect(()=>{
        var _statsInitialDataList = JSON.parse(localStorage.getItem("statsInitialData"));
        const initialDataIndex = _statsInitialDataList?.length > 0 ? _statsInitialDataList?.findIndex(e=>e.matchAccessKey === initialLocalData?.matchAccessKey) : -1
        if (initialDataIndex === -1) 
        {
            _statsInitialDataList = _statsInitialDataList?.length > 0 ? [..._statsInitialDataList, initialLocalData] : [initialLocalData]
            localStorage.setItem("statsInitialData", JSON.stringify(_statsInitialDataList))
        } else {
            const index = _statsInitialDataList?.findIndex(e=>e.matchAccessKey === initialLocalData?.matchAccessKey)
            _statsInitialDataList[index] = initialLocalData
            localStorage.setItem("statsInitialData", JSON.stringify(_statsInitialDataList))
        }
        // localStorage.setItem("statsInitialData", JSON.stringify(null))
    },[initialLocalData])
    
    const switchActiveTeam = () => {
        activeTeamRef.current = activeTeamRef.current === 2 ? 1 : 2
        setActiveTeam(activeTeamRef.current)
    }

    const handleActiveTeam = (_activeTeam) => {
        activeTeamRef.current = _activeTeam
        setActiveTeam(_activeTeam)
    }

    useEffect(()=>{
        activeTeamRef.current = activeTeam
    },[activeTeam])

    useEffect(()=>{
        rotationARef.current = rotationA
        rotationBRef.current = rotationB
    },[rotationA, rotationB])

    const actions = [
        {
            ...actionsUtils.attack,
        },
        {
            ...actionsUtils.block,
        },
        {
            ...actionsUtils.set,
        },
        {
            ...actionsUtils.reception,
        },
        {
            ...actionsUtils.dig,
        },
        {
            ...actionsUtils.serve,
        },
    ]
    
    const currentCourtPositionRef = useRef(null)
    const currentQualityRef = useRef();
    const currentPlayerRef = useRef()
    let currentPlayerAction = null;
    const currentRallyRef = useRef();
    const [selectedPosition, setSelectedPosition] = useState()
    const activeTeamRef = useRef(activeTeam)
    const rotationARef = useRef()
    const rotationBRef = useRef()
    const [teamServing, setTeamServing] = useState()
    const teamServingRef = useRef(teamServing)
    const playersARef = useRef(playersA)
    const playersBRef = useRef(playersB)
    const speachtTextRef = useRef('')
    
    useEffect(()=>{
        currentRallyRef.current = currentRallyContext

        var _initialLocalData = {...initialLocalData}
        _initialLocalData.currentRallyContext = currentRallyContext
        setInitialLocalData(_initialLocalData)

    },[currentRallyContext])

    const setCurrentRally = (_rally) => {
        currentRallyRef.current = _rally
        setCurrentRallyContext(_rally)
    }

    const getRallyPhase = () => {
        if (teamServingRef?.current !== activeTeamRef.current){
            if (currentRallyRef.current.length <= 3)
                return 'fbso'
            else
                return 'so'
        } else {
            return 'tra'
        }
    }

    const finishAction = () => {
        currentPlayerAction = {
            player: currentPlayerRef.current ? currentPlayerRef.current : null, 
            player_id: currentPlayerRef.current ? currentPlayerRef.current.player.id : null, 
            playerName: '', 
            action_number: currentActionRefContext.current?.number, 
            action: currentActionRefContext.current?.id, 
            position_action: currentCourtPositionRef.current ? currentCourtPositionRef.current : null, 
            quality_value: currentQualityRef.current ? currentQualityRef.current.number : 1, 
            quality: currentQualityRef.current ? currentQualityRef.current.id : 2, 
            rotation: activeTeamRef.current === 1 ? rotationARef.current : rotationBRef.current, 
            position: currentPlayerRef.current ? currentPlayerRef.current.courtPosition : "",
            team: activeTeamRef.current === 1 ? gridData.teamA : gridData.teamB, 
            activeTeam: activeTeamRef.current,
            rally_phase: getRallyPhase(),
            set_match: setMatchContext,
            speach_text: speachtTextRef.current,
        }
        currentCourtPositionRef.current = null
        setCurrentRally([...currentRallyRef.current, currentPlayerAction])
        currentPlayerRef.current = null
    }

    const setCurrentPlayerAByPosition = (_position) => {
        if (playersARef.current && _position && typeof(_position) === 'number') {
            const _index = playersARef.current.findIndex(e=>Number(e.position) === Number(_position))
            currentPlayerRef.current = _index >= 0 ? {...playersARef.current[_index]} : null
        }
    }

    const setCurrentPlayerBByPosition = (_position) => {
        if (playersBRef.current && _position && typeof(_position) === 'number') {
            const _index = playersBRef.current.findIndex(e=>Number(e.position) === Number(_position))
            currentPlayerRef.current = _index >= 0 ? {...playersBRef.current[_index]} : null
        }
    }

    const setCurrentPlayerAByNumber = (_number) => {
        if (playersARef.current && _number && typeof(Number(_number)) === 'number') {
            const _index = playersARef.current.findIndex(e=>Number(e.number) === Number(_number))
            currentPlayerRef.current = _index >= 0 ? {...playersARef.current[_index]} : null
        }
    }

    const setCurrentPlayerBByNumber = (_number) => {
        if (playersBRef.current && _number && typeof(Number(_number)) === 'number') {
            const _index = playersBRef.current.findIndex(e=>Number(e.number) === Number(_number))
            currentPlayerRef.current = _index >= 0 ? {...playersBRef.current[_index]} : null
        }
    }

    const handleTeamServing = (team1or2) => {
        teamServingRef.current = team1or2
        setTeamServing(team1or2)
    }

    const finishGameRally = () => {
        var hasPoint = false
        var actionPoint = null
        for (let index = 0; index < currentRallyRef.current.length; index++) {
            const item = currentRallyRef.current[index];
            hasPoint = item.action_number === "ser" && (Number(item.quality_value) === 4 || Number(item.quality_value) === 0) ? true : false

            if ((Number(item.quality) === 3 && (
                item.action_number === "att" || 
                item.action_number === "blo"))) {
                hasPoint = true
                actionPoint = item
            } else if (Number(item.quality) === 1 && (
                item.action_number === "att" || 
                item.action_number === "set" || 
                item.action_number === "ser" || 
                item.action_number === "blo" || 
                item.action_number === "dig")) {
                hasPoint = true
                actionPoint = item
            }
            if (hasPoint) break;
        }

        hasPoint ? 
        createRally({match_id: gridData.matchId, created_by: userAuth.user.access_key, rally: currentRallyRef.current}).then((res)=>{
            if (res) {
                if (Number(actionPoint?.quality_value) === 2 && enabledAutoRotationContext) {
                    if (actionPoint.activeTeam !== teamServingRef.current) {
                        if (actionPoint.activeTeam === 1) {
                            handleRotationA(rotationARef.current)
                            handleActiveTeam(1)
                        } else {
                            handleRotationB(rotationBRef.current)
                            handleActiveTeam(2)
                        }
                    }
                } else if (Number(actionPoint?.quality_value) === 0 && enabledAutoRotationContext) {
                    if (actionPoint.activeTeam === teamServingRef.current) {
                        if (actionPoint.activeTeam === 1) {
                            handleRotationB(rotationBRef.current)
                            handleActiveTeam(2)
                        } else {
                            handleRotationA(rotationARef.current)
                            handleActiveTeam(1)
                        }
                    } else {
                        if (actionPoint.activeTeam === 2) {
                            handleActiveTeam(1)
                        } else {
                            handleActiveTeam(2)
                        }
                    }
                }
                setRefreshReportData(true)
                restartRally()
                toast.success("Rally saved!")
            }
        }).catch((error)=>{
        }) : toast.error("Click Score or Error")
    }

    const changePlayerPosition = (_pos) => {
        if (rotationA && rotationB && _pos){
            var newPosition = _pos - 1
            newPosition = newPosition === 0 ? 6 : newPosition
            return newPosition
        }
    }

    useEffect(()=>{
        rotationARef.current = rotationA
    },[rotationA])

    useEffect(()=>{
        rotationBRef.current = rotationB
    },[rotationB])

    useEffect(()=>{
        if (!rotateA) return
        rotationARef.current = rotationA
        var _initialLocalData = {...initialLocalData}
        _initialLocalData.rotationA = rotationA
        setInitialLocalData(_initialLocalData)
        if (playersA) {
            var tempPlayers = [...playersA]
            setPlayersA(tempPlayers.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
        if (activePlayersAContext) {
            var tempPlayersActiveContext = [...activePlayersAContext]
            setActivePlayersAContext(tempPlayersActiveContext.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
        setRotateA(false)
    },[rotateA])

    useEffect(()=>{
        if (!rotateB) return
        rotationBRef.current = rotationB
        var _initialLocalData = {...initialLocalData}
        _initialLocalData.rotationB = rotationB
        setInitialLocalData(_initialLocalData)
        if (playersB){
            var tempPlayers = [...playersB]
            setPlayersB(tempPlayers.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
        if (activePlayersBContext) {
            var tempPlayersActiveContext = [...activePlayersBContext]
            setActivePlayersBContext(tempPlayersActiveContext.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
        setRotateB(false)
    },[rotateB])

    const restartRally = () => {
        currentActionRefContext.current = actions[5]
        setCurrentRally([])
    }

    const gridCss = 'flex flex-1 mt-4 text-2xl'
    const columnCss = 'flex flex-column flex-1 gap-2'

    const processTranscript = (text) => {
        let processedText = text
        .replace(':',' ')
        .replace('.',' ')
        .replace(/\bGig\b/g, 'dig')
        .replace(/\bgig\b/g, 'dig')
        .replace(/\bdigit\b/g, 'dig')
        .replace(/\bDigit\b/g, 'dig')
        .replace(/\bone\b/g, '1')
            .replace(/\bOne\b/g, '1')
            .replace(/\bto\b/g, '2')
            .replace(/\bTo\b/g, '2')
            .replace(/\btwo\b/g, '2')
            .replace(/\bTwo\b/g, '2')
            .replace(/\btree\b/g, '3')
            .replace(/\bTree\b/g, '3')
            .replace(/\bthree\b/g, '3')
            .replace(/\bThree\b/g, '3')
            .replace(/\bfor\b/g, '4')
            .replace(/\bFor\b/g, '4')
            .replace(/\bfour\b/g, '4')
            .replace(/\bFour\b/g, '4')
            .replace(/\bhate\b/g, '8')
            .replace(/\bHate\b/g, '8')
            .replace(/\bfive\b/g, '5')
            .replace(/\bFive\b/g, '5')
            .replace(/\bsix\b/g, '6')
            .replace(/\bSix\b/g, '6');
            
        return processedText;
    };


    useEffect(()=>{
        if (!listening && transcript) {
            setTranscriptProcessed(processTranscript(transcript))
            playOff()
        }
    },[listening])

    const handleSpeachTeamA = () => {
        setTeamListening(1)
        handleActiveTeam(1)
        playOn();

        SpeechRecognition.startListening()
    } 

    const handleSpeachTeamB = () => {
        setTeamListening(2)
        handleActiveTeam(2)
        playOn();

        SpeechRecognition.startListening()
    } 

    useEffect(()=>{
        transcriptProcessed && handleCreatePlayerAction()
    },[transcriptProcessed])

    const handleCreatePlayerAction = () => {
        const _actionsSplited = transcriptProcessed.split(' then ')
        for (let index = 0; index < _actionsSplited.length; index++) {
            const element = _actionsSplited[index];
            speachtTextRef.current = element
            
            const _codesArray = element.split(' ')

            _codesArray[0] && teamListening === 1 ? setCurrentPlayerAByNumber(_codesArray[0]) : setCurrentPlayerBByNumber(_codesArray[0])
    
            _codesArray[1] && handleSelectAction(_codesArray[1])
    
            const _position = _codesArray[2] && _codesArray[2].length > 1 ? _codesArray[2].charAt(0) : _codesArray[2]
            _position && handleCourtPosition(_position)
    
            const _quality = _codesArray[2] && _codesArray[2].length > 1 ? _codesArray[2].charAt(1) : _codesArray[3] ? _codesArray[3] : null
            _quality && handleActionQuality(_quality)
    
            finishAction()
    
            if (currentActionRefContext.current === actions[3]) handleCreateServe(_position, teamListening)
        }
    }

    const handleCreateServe = (_position, _teamListening) => {
        _teamListening === 1 ? setCurrentPlayerBByPosition(1) : setCurrentPlayerAByPosition(1)
        _teamListening === 1 ? handleActiveTeam(2) : handleActiveTeam(1)
        
        currentActionRefContext.current = actions[5] // serve
        
        handleCourtPosition(_position)

        // handleActionQuality(_quality)
        if (currentQualityRef.current) {
            const serQuality =  (currentQualityRef.current.id === 5 || currentQualityRef.current.id === 4) ? 'D' : 
            (currentQualityRef.current.id === 3) ? 'C' : 
            (currentQualityRef.current.id === 2) ? 'B' : 
            (currentQualityRef.current.id === 1) ? 'A' : null
            handleActionQuality(serQuality)
        }

        finishAction()

        setCurrentRally([currentRallyRef.current[1], currentRallyRef.current[0]]) // change serve and reception order
    }

    const handleActionQuality = (_quality) => {
        if (!_quality || !currentActionRefContext.current) return
        const _qualityLower = _quality.replace('8', 'a').toLowerCase()
        let qualityConverted = ''
        
        if (currentActionRefContext.current.number === 'rec' || currentActionRefContext.current.number === 'ser'){
            qualityConverted = _qualityLower === 'a' ? 5 : 
            _qualityLower === 'b' ? 4 : 
            _qualityLower === 'c' ? 3 : 
            _qualityLower === 'd' ? 2 : 
            _qualityLower === 'e' ? 1 : 
            _qualityLower === 'error' ? 1 : null 
        } else {
            qualityConverted = _qualityLower === 'a' ? 3 : _qualityLower === 'b' ? 2 : _qualityLower === 'c' ? 1 : null 
        }
        
        currentQualityRef.current = qualityConverted ? {id: qualityConverted, number: qualityConverted-1} : {id: 2, number: 1}
    }

    const handleCourtPosition = (_position) => {
        currentCourtPositionRef.current = Number(_position) ? Number(_position) : null
    }

    const handleSelectAction = (_actionText) => {
        currentActionRefContext.current = 
        (_actionText.toLowerCase() === 'serve') ? actions[5] :
        (_actionText.toLowerCase() === 'reception' || _actionText.toLowerCase() === 'pass' || _actionText.toLowerCase() === 'r') ? actions[3] :
        (_actionText.toLowerCase() === 'set' || _actionText.toLowerCase() === 's') ? actions[2] :
        (_actionText.toLowerCase() === 'attack' || _actionText.toLowerCase() === 'a' || _actionText.toLowerCase() === 'swing' || _actionText.toLowerCase() === 'hit') ? actions[0] :
        (_actionText.toLowerCase() === 'block' || _actionText.toLowerCase() === 'b') ? actions[1] :
        (_actionText.toLowerCase() === 'dig' || _actionText.toLowerCase() === 'd') ? actions[4] : 
        null

        if (currentActionRefContext.current === actions[3]){
            activeTeamRef.current === 1 ? handleTeamServing(2) : handleTeamServing(1) 
        }
    }

    return (
        <div>
            <div>
                <MenuButtonsComponent 
                    gridLayout={gridLayout} 
                    setGridLayout={setGridLayout} 
                    selectedPosition={selectedPosition} 
                    setTeamServing={setTeamServing}
                    courtType={''}
                    saveRally={finishGameRally}
                    restartRally={restartRally}
                />
                <div className={gridCss}>
                    <div className={columnCss}>
                        <Button onClick={handleSpeachTeamA} disabled={listening} className={'flex justify-content-center h-6rem md:h-12rem'}>
                                {listening ? <PiMicrophoneSlash size={48} /> : <PiMicrophone size={48} />}
                        </Button>
                    </div>
                    <Divider layout="vertical" />
                    <div className={columnCss}>
                        <Button onClick={handleSpeachTeamB} disabled={listening} className={'flex justify-content-center h-6rem md:h-12rem'}>
                                {listening ? <PiMicrophoneSlash size={48} /> : <PiMicrophone size={48} />}
                        </Button>
                    </div>                  
                </div>
            </div>
            <Divider />
            <div className={'flex flex-1 flex-column'}>
                <RallyListComponent />
            </div>
            <ShowGameStats refresData={refreshReportData} setRefreshReportData={setRefreshReportData} />
        </div>
    )

}

export default SpeachStatisticsComponent